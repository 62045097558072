export const CONNECTING = "CONNECTING";
export const CONNECTED = "CONNECTED";
export const DISCONNECTED = "DISCONNECTED";
export const CONTENTS = "CONTENTS";
export const KIOSKS = "KIOSKS";
export const OB_KIOSKS = "OB_KIOSKS";
export const KIOSK_CONTENTS = "KIOSK_CONTENTS";
export const LOADING = "LOADING";
export const BALANCES = "BALANCES";
export const LOGIN = "LOGIN";
export const LOGOUT = "LOGOUT";
export const FAVORITE_NFT = "FAVORITE_NFT";
export const FAVORITE_COLLECTION = "FAVORITE_COLLECTION";
export const SEARCH_FAVORITE_NFT = "SEARCH_FAVORITE_NFT";
export const SEARCH_FAVORITE_AUCTION = "SEARCH_FAVORITE_AUCTION";
export const SEARCH_FAVORITE_COLLECTION = "SEARCH_FAVORITE_COLLECTION";
export const WON_AUCTIONS = "WON_AUCTIONS";
export const WIN_LISTING = "WIN_LISTING";
export const LISTINGS_SEARCH_RESULTS = "LISTINGS_SEARCH_RESULTS";
export const LENDINGS_SEARCH_RESULTS = "LENDINGS_SEARCH_RESULTS";
export const REMOVE_LISTINGS_SEARCH_RESULTS = "REMOVE_LISTINGS_SEARCH_RESULTS";
export const COLLECTIONS_SEARCH_RESULTS = "COLLECTIONS_SEARCH_RESULTS";
export const AUCTIONS_SEARCH_RESULTS = "AUCTIONS_SEARCH_RESULTS";
export const CREATORS_SEARCH_RESULTS = "CREATORS_SEARCH_RESULTS";
export const GAMES_SEARCH_RESULTS = "GAMES_SEARCH_RESULTS";
export const LAUNCHPADS_SEARCH_RESULTS = "LAUNCHPADS_SEARCH_RESULTS";
export const LAUNCHPADS_MODULE_RESULTS = "LAUNCHPADS_MODULE_RESULTS";
export const SEARCH_LOADING = "SEARCH_LOADING";
export const SET_CREATORS = "SET_CREATORS";
export const FOLLOW = "FOLLOW";
export const REFRESH_CREATOR = "REFRESH_CREATOR";
export const HIDE_NFT = "HIDE_NFT";
export const BAGS_SEARCH_RESULTS = "BAGS_SEARCH_RESULTS";
