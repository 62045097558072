import React from "react";
import { useSelector } from "react-redux";
import { ellipsifyString } from "utils/formats";
import styled, { css } from "styled-components";
import LazyLoadImage from "./LazyLoadImage";
import { FlexColumn, FlexRow } from "utils/styled";
import { useProfileMenu } from "components/utils/ProfileMenuProvider";
import { useWindowSize } from "utils/hooks";

export const MenuWrapper = styled.div`
  font-family: "Inter", sans-serif;
  color: #808191;
  padding: 0 10px;
  height: 100%;
  margin-top: 75px;
`;

export const ListItemWrapper = styled.div`
  margin-top: 8px;
  margin-bottom: 8px;
  padding: 0;
  color: inherit;
  text-decoration: none;

  &:hover {
    text-decoration: none;
  }

  ${(props) =>
    props.disabled &&
    css`
      pointer-events: none;
      display: block;
      margin: 0;
    `};
`;

export const NavButtonText = styled.span`
  font-weight: 600;
  display: ${({ open }) => (open ? "inline" : "none")};
`;

const ListItem = styled.div`
  margin-bottom: 1.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  border-radius: var(--border-radius-md);
  cursor: pointer;
  background: var(--primary-color);
  border: 2px solid var(--primary-color7);
  font-size: 18px;
  font-weight: 600;
  height: 56px;
  transition: all 0.3s;
  white-space: pre-line;
  word-wrap: break-word;
  gap: 1rem;

  :hover {
    background: var(--hover-color);
    border: 2px solid var(--primary-color3);
  }

  ${({ isActive }) =>
    isActive &&
    css`
      background: var(--primary-color3);
      border: 2px solid var(--primary-color3);
      :hover {
        background: var(--hover-color2);
      }
    `};
`;

export const MenuNameBlock = styled.div`
  margin-top: 33px;
  margin-bottom: 16px;
  font-size: 14px;
  line-height: 16px;
  padding-left: ${({ open }) => (open ? "30px" : "0px")};
  text-align: ${({ open }) => (open ? "left" : "center")};
`;

export const Name = styled.div`
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
  position: absolute;
  width: 168px;
  left: 24px;
  top: 138px;
`;

export const Description = styled.div`
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  position: absolute;
  width: 168px;
  left: 24px;
  top: 214px;
`;

const ProfileImageWrapper = styled.div`
  height: 100px;
  width: 100px;
  aspect-ratio: 1;
  @media only screen and (max-width: 991px) {
    height: 140px;
    width: 140px;
  }
`;

const CloseButton = styled.button`
  & {
    background-color: transparent;
    color: var(--primary-color2);
    border: none;
    border-radius: 0;
    padding: 2rem;
    transition: unset;
    position: absolute;
    right: 0;
    top: 0;
  }
`;

const CloseIcon = styled.i`
  font-size: 28px;
`;

const ProfileSidebar = () => {
  const screenWidth = useWindowSize().width;
  const { menuData, menuIndex, setMenuIndex, showMenu, setShowMenu } = useProfileMenu();

  const user = useSelector((state) => state.user);
  const fallback = `https://gravatar.com/avatar/${user._id}?f=y&d=identicon&size=200`;

  const tabSelectHandler = (index) => {
    setMenuIndex(index);
    setShowMenu(false);
  };

  const handleCloseMenu = () => {
    setShowMenu(false);
  };

  // if (screenWidth > 767 && !showMenu) return null;

  return (
    <div
      id="side-bar"
      className={`col-box-17 side-bar style-3 item ${showMenu ? `active` : ``}`}
    >
      {screenWidth < 767 && (
        <CloseButton key="profile-menu" onClick={() => handleCloseMenu()}>
          <CloseIcon className="fas fa-times fa-lg" />
        </CloseButton>
      )}
      <FlexRow style={{ gap: "2rem", height: "max-content", padding: "3rem 2rem" }}>
        <ProfileImageWrapper>
          <LazyLoadImage
            className="profile-image"
            src={user.avatar_url}
            fallback={fallback}
          />
        </ProfileImageWrapper>
        <FlexColumn className="justify-content-evenly">
          <h5>{user?.display_name ?? ellipsifyString(user?.account_address, 10)}</h5>
          <h6>Novice</h6>

          <div className="progress fullWidth" style={{ height: "4px" }}>
            <div
              className="progress-bar progress-bar-striped bg-info"
              role="progressbar"
              style={{ width: "0%", height: "4px" }}
            />
          </div>
          <span>LEVEL 1</span>
        </FlexColumn>
      </FlexRow>
      <div className="wrap-category" style={{ padding: "0 2rem" }}>
        {menuData.map(({ name, link, Icon }, index) => (
          <ListItemWrapper key={index} onClick={() => tabSelectHandler(index)}>
            <ListItem isActive={menuIndex === index}>
              {Icon}
              <span>{name}</span>
            </ListItem>
          </ListItemWrapper>
        ))}
      </div>
    </div>
  );
};

export default ProfileSidebar;
