import React from "react";
import { Link } from "react-router-dom";
import LazyLoadImage from "components/layouts/LazyLoadImage";
import {
  FlexRow,
  GridRow,
  NameImageWrapper,
  RowImage,
  RowName,
  RowText,
  TextOverflow,
} from "utils/styled";

const ListBagCard = ({ item }) => {
  const {
    collection_id: _id,
    featured_image: image,
    title: name,
    collection_name,
    limit,
    nftCount,
    uniqueCount,
  } = item;
  const to = `/edit-bags/${_id}`;

  const renderColumnFields = () => {
    const orderedFields = [collection_name, limit, nftCount, uniqueCount];

    return orderedFields.map((field, index) => (
      <FlexRow>
        <RowText key={"orderedFields" + index}>{field}</RowText>
      </FlexRow>
    ));
  };

  return (
    <Link to={to}>
      <GridRow>
        <NameImageWrapper>
          <RowImage>
            <LazyLoadImage
              className="launchpad-img-row"
              src={image}
              key={`featured-${_id}`}
            />
          </RowImage>
          <TextOverflow>
            <RowName>{name ?? "~"}</RowName>
          </TextOverflow>
        </NameImageWrapper>
        {renderColumnFields()}
      </GridRow>
    </Link>
  );
};

export default ListBagCard;
