import { useParams, useSearchParams } from "react-router-dom";
import { Field, reduxForm, change } from "redux-form";
import { useDispatch, useSelector } from "react-redux";
import { withdrawFromBag } from "web3/sui";
import { renderFormV2 } from "utils/form";
import { getLaunchpad } from "utils/api";
import { useState } from "react";
import { useEffect } from "react";
import { sleep } from "utils/time";
import ToastPopup from "components/utils/ToastPopup";
import { isArray } from "lodash";
import { Modal } from "react-bootstrap";
import LoadingButton from "components/button/LoadingButton";
// import { ipfsConvert, downloadJSONFile } from "utils/formats";

const formName = "batch-create-nft";

const validate = (/* values , dispatch */) => {
  const errors = {};
  // if (!values.collection) {
  //   errors.collection = "Please select a collection";
  // }
  return errors;
};

const BatchBagWithdrawModal = ({
  onBatchCreate,
  item,
  onHide,
  handleSubmit,
  pristine,
  submitting,
}) => {
  const dispatch = useDispatch();
  const params = useParams();
  const { collection, index, bag } = item;
  const nftCount = bag?.nftCount || 0;
  const user = useSelector((state) => state.user);
  const [searchParams, setSearchParams] = useSearchParams();
  const [batchSize, setBatchSize] = useState(
    parseInt(searchParams.get("batchSize")) || 50
  );
  const [next, setNext] = useState(parseInt(searchParams.get("start")) || 0);
  const [batchType, setBatchType] = useState(searchParams.get("type") || "withdraw");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (user._id) {
      // fetchLaunchpad();
    }
  }, [user]);

  const submit = async () => {
    setLoading(true);
    withdrawBatch(next, next + batchSize);
  };

  const withdrawBatch = async (start, end) => {
    const txn = await withdrawFromBag(
      collection,
      bag,
      // try to withdraw batchSize, or however many are still left
      Math.min(batchSize, nftCount - start)
    );
    if (txn.status === "success") {
      await sleep(2000);
      setNext(start + batchSize);
      searchParams.set("start", start + batchSize);
      searchParams.set("batchSize", batchSize);
      setSearchParams(searchParams);
    } else {
      ToastPopup("An unknown error occured", "error");
      setLoading(false);
    }
  };

  useEffect(() => {
    if (next > 0 && batchType === "withdraw") {
      if (next > nftCount) {
        ToastPopup("NFTs withdrawn successfully!", "success");
        setLoading(false);
        onBatchCreate();
      } else {
        withdrawBatch(next, next + batchSize);
      }
    }
  }, [next]);

  return (
    <Modal size="sm" show={bag} onHide={onHide}>
      <Modal.Header closeButton />
      <div className="modal-body space-y-10 pd-40 align-items-center">
        <h2 style={{ marginBottom: "4rem" }}>
          Withdraw NFTs from {bag?.title || `Tier #${index + 1}`}
        </h2>
        <div className="row">
          <div className="col-xl-12 col-lg-6 col-md-9 col-12">
            <h4 className="title-list-item">Batch Withdraw Progress</h4>
            <div className="sc-card-product">
              <div className="meta-info pt-5 pl-2">
                <p>Up next: #{Math.min(next + 1, nftCount + 1)}</p>
                <p>
                  Withdrawn: {Math.min(next, nftCount)}/{nftCount}
                </p>
              </div>
              <div className="card-bottom"></div>
            </div>
          </div>
          <div className="col-xl-12 col-lg-12 col-md-12 col-12">
            <div className="flat-tabs tab-list-item">
              <form onSubmit={handleSubmit(submit)}>
                <Field
                  type="text"
                  name="bag.title"
                  title="Tier"
                  props={{
                    value: bag?.title || `Tier #${index}`,
                  }}
                  disabled
                  component={renderFormV2}
                />
                <LoadingButton
                  loading={loading}
                  disabled={loading || submitting}
                  type="submit"
                  className="fullWidth mt-md-4"
                >
                  Withdraw NFTs
                </LoadingButton>
              </form>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default reduxForm({
  form: formName,
  validate,
})(BatchBagWithdrawModal);
