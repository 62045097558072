import {
  isValidSuiObjectId,
  normalizeSuiAddress as _normalizeSuiAddress,
  SUI_CLOCK_OBJECT_ID,
  SUI_FRAMEWORK_ADDRESS,
} from "@mysten/sui.js/utils";
import { TransactionBlock } from "@mysten/sui.js/transactions";
import { SuiClient } from "@mysten/sui.js/client";

import {
  queryTransferPolicy,
  // queryOwnedTranferPolicyCap,
} from "@mysten/kiosk";
import { ethos } from "ethos-connect";
// import { values } from "lodash";
import { IS_PROD } from "utils/environments";
import { suiToMyst, formatSuiItem } from "utils/formats";
import { filter } from "utils/performance";
import { addToTransaction, makeTransactionBlock } from "./txnbuilder";
import {
  auctionKiosk,
  listKiosk,
  buyKiosk,
  finishLendingKioskNFT,
  burnKioskNFT,
  returnKioskNFT,
  borrowKioskNFT,
  winKioskAuction,
  lendKioskNFT,
} from "./standards/mysten";

import {
  buyOB,
  listOB,
  auctionOB,
  winOBAuction,
  finishLendingOBNFT,
  burnOBNFT,
  returnOBNFT,
  borrowOBNFT,
  lendOBNFT,
} from "./standards/originByte";

import {
  listNonstandard,
  buyNonstandard,
  auctionNonstandard,
  winNonstandardAuction,
} from "./standards/nonstandard";

export const normalizeSuiAddress = (a) => _normalizeSuiAddress(a);
export const SuiAddress = normalizeSuiAddress(SUI_FRAMEWORK_ADDRESS);
export const SUI = `${SuiAddress}::sui::SUI`;

let getNetwork = () => {
  let pools = [
    "https://api.shinami.com:443/node/v1/sui_testnet_d00e9e3e1e8f3632d0cc5041229b4b91",
    "https://api.shinami.com:443/node/v1/sui_testnet_d00e9e3e1e8f3632d0cc5041229b4b91",
  ];
  let weights = [33, 33];

  if (!IS_PROD) {
    pools.pop();
    weights.pop();
  }

  for (let i = 1; i < weights.length; i++) {
    weights[i] += weights[i - 1];
  }

  var random = Math.random() * weights[weights.length - 1];

  for (let i = 0; i < weights.length; i++) {
    if (weights[i] > random) {
      return pools[i];
    }
  }
};

export const network = getNetwork();

let provider = new SuiClient({
  url: network,
});
let signer = false;

let settings = {
  nft_bytes: "",
  allowlist: "",
  package_id: "",
  module_name: "",
  market: {},
  admin_address: "",
  tags: [],
  collateral_fee: 0,
};

let userAddress = false;

export const getUserAddress = () => userAddress;

export const updateSettings = async (newSettings, marketInfo) => {
  settings = newSettings;
  if (marketInfo) {
    settings.market.collateral_fee = marketInfo.data.collateral_fee;
    settings.market.fee_bps = marketInfo.data.fee_bps;
    settings.market.owner = marketInfo.data.owner;
    settings.package_id = marketInfo.type.split("::")[0];
  }
};

export const currentSettings = () => settings;

export const formatTransaction = (tx) => {
  const response = {};
  if (tx.EffectsCert) {
    response.effects = tx.EffectsCert.effects.effects;
    response.certificate = tx.EffectsCert.certificate;
    response.status = tx.EffectsCert.effects.effects.status.status;
  } else {
    response.effects = tx.effects;
    response.certificate = tx.certificate;
    response.status = tx.effects.status.status;
  }
  return response;
};

export const getTxObjects = async (txResults) => {
  if (txResults.effects.created) {
    const created = txResults.effects.created.map((item) => item.reference.objectId);
    const createdInfo = await getObjectsInfo(created, {
      showContent: true,
      showType: true,
    });
    let packageObjectId = false;
    let createdObjects = [];

    createdInfo.forEach((item) => {
      if (item.data?.dataType === "package") {
        packageObjectId = item.reference.objectId;
      } else {
        createdObjects.push(item);
      }
    });
    return [packageObjectId, createdObjects];
  }
  return [null, null];
};

export const collectionType = (collection) =>
  `${collection.object_id}::${collection.module_name}::${collection.nft_name}`;

const makeTxnBlock = () => {
  const txb = new TransactionBlock();
  txb.setSender(userAddress);
  return txb;
};

const promiseTransact = async (txArray, { payment, dry_run } = {}) => {
  let transactions = Array.isArray(txArray) ? txArray : [txArray];
  let txb = false;
  try {
    txb = makeTransactionBlock(transactions, userAddress);
  } catch (e) {
    console.log(transactions);
    throw e;
  }

  txb.setSender(userAddress);

  // let gasBudget = Math.min(gasBalance, 1000000);
  if (payment) {
    txb.setGasPayment([txb.object(payment)]);
  }

  console.log(txb.blockData);
  // ensure transaction actually works before trying it with a wallet
  // let res = await txb.build({ provider });
  if (dry_run) {
    let res = await provider.devInspectTransactionBlock({
      transactionBlock: txb,
      sender: userAddress,
    });
    return Promise.resolve(res);
  }

  return new Promise((resolve, reject) => {
    ethos
      .signTransactionBlock({
        signer,
        transactionInput: {
          transactionBlock: txb,
          acccount: userAddress,
        },
      })
      .then((signedTx) =>
        provider
          .executeTransactionBlock({
            transactionBlock: signedTx.transactionBlockBytes,
            signature: signedTx.signature,
            options: {
              showEffects: true,
            },
            requestType: "WaitForLocalExecution",
          })
          .then((tx) => {
            if (tx?.effects?.status?.status === "success") {
              resolve(formatTransaction(tx));
            } else if (tx?.effects?.status?.error) {
              reject(tx?.effects?.status?.error);
            }
            reject(tx);
          })
      )
      .catch((e) => {
        console.log(e);
        reject(e);
      });
  });
};

export const signAndExecuteTransactionBlock = (txb) => {
  return new Promise((resolve, reject) => {
    console.log(txb.blockData);

    ethos
      .signTransactionBlock({
        signer,
        transactionInput: {
          transactionBlock: txb,
          acccount: userAddress,
        },
      })
      .then((signedTx) =>
        provider
          .executeTransactionBlock({
            transactionBlock: signedTx.transactionBlockBytes,
            signature: signedTx.signature,
            options: {
              showEffects: true,
            },
            requestType: "WaitForLocalExecution",
          })
          .then((tx) => {
            if (tx?.effects?.status?.status === "success") {
              resolve(formatTransaction(tx));
            } else if (tx?.effects?.status?.error) {
              reject(tx?.effects?.status?.error);
            }
            reject(tx);
          })
      )
      .catch((e) => {
        console.log(e);
        reject(e);
      });
  });
};

export const signTransactionBlock = (data) => {
  return new Promise((resolve, reject) => {
    console.log(data);
    ethos
      .signTransactionBlock({
        signer,
        transactionInput: {
          transactionBlock: TransactionBlock.from(Buffer.from(data)),
          acccount: userAddress,
        },
      })
      .then((signedTx) => resolve(signedTx))
      .catch((e) => {
        console.log(e);
        reject(e);
      });
  });
};

export const showWallet = () => ethos.showWallet();

export const loginSignature = async (signer) => {
  var enc = new TextEncoder();
  let data = `keepsake.gg::login::${Date.now()}`;
  const message = enc.encode(data);
  let response = await signer.signPersonalMessage({ message });
  return { data, signedMessageResponse: response.signature || response };
};

export const asValidObjectID = (value) => {
  const prepend = "0x";
  let retval = value.replace(/[^A-Za-z0-9]/g, "");
  if (retval.length < 2) {
    return retval;
  }
  if (retval.startsWith(prepend)) {
    retval = retval.substring(2);
  }
  return normalizeSuiAddress(retval.replace(/[^A-Fa-f0-9]/g, ""));
};

export const padType = (type) => {
  const types = type.split("::");
  return asValidObjectID(types[0]) + "::" + types[1] + "::" + types[2];
};

export const isValidObjectId = (value) => isValidSuiObjectId(value);

export const getProvider = () => provider;

export const setProvider = (newProvider, newSigner, newAddress) => {
  if (newProvider) {
    // provider = newProvider;
    // serializer = new LocalTxnDataSerializer(newProvider);
  }
  signer = newSigner;
  userAddress = newAddress;
};

export const disconnect = () => {
  ethos.disconnect(true);
  userAddress = false;
};

export const getUserObjects = ({
  cursor = null,
  limit = 50,
  address,
  filter,
  // consider removing showOwner
  options = { showType: true, showContent: true, showOwner: true, showDisplay: true },
}) =>
  provider
    .getOwnedObjects({
      owner: address || userAddress,
      cursor,
      limit,
      filter,
      options,
    })
    .then((res) => {
      return { ...res, ...{ data: res.data.map((a) => formatSuiItem(a)) } };
    });

export const getObjectsOwnedByObject = (objectId, cursor, limit) =>
  provider.getDynamicFields({ parentId: objectId, cursor, limit });

export const devInspectTransaction = (sender, transactionBlock) =>
  provider.devInspectTransactionBlock({
    transactionBlock,
    sender: sender || userAddress,
  });

export const dryRunTransactionBlock = (txBytes) =>
  provider.dryRunTransactionBlock({ transactionBlock: txBytes });

export const getObjectInfo = (
  objectId,
  options = { showType: true, showContent: true, showOwner: true }
) =>
  provider
    .getObject({
      id: objectId,
      options: options,
    })
    .then((a) => formatSuiItem(a));

export const getObjectsInfo = async (
  objectIds,
  options = { showContent: true, showType: true }
) => {
  let fullContents = [];
  if (objectIds.length > 50) {
    let promises = [];
    let i = 0;
    while (i < objectIds.length) {
      promises.push(
        provider.multiGetObjects({ ids: objectIds.slice(i, i + 50), options })
      );
      i += 50;
    }
    let data = await Promise.all(promises);
    i = 0;
    data.forEach((element) => {
      fullContents = fullContents.concat(element);
    });
    return fullContents.map((a) => formatSuiItem(a));
  } else {
    fullContents = await provider
      .multiGetObjects({ ids: objectIds, options })
      .then((info) => info.map((a) => formatSuiItem(a)));
    return fullContents;
  }
};

export const getTransaction = (txhash) =>
  provider
    .getTransactionBlock({
      digest: txhash,
      options: {
        showEvents: true,
        showObjectChanges: true,
        showEffects: true,
      },
    })
    .then((tx) => formatTransaction(tx));

export const getEvents = (query, cursor = null, limit = "50", order = "Ascending") =>
  provider.queryEvents({ query });
/*
    | "All"
    | { "Transaction": TransactionDigest }
    | { "MoveModule": { package: ObjectId, module: string } }
    | { "MoveEvent": string }
    | { "EventType": EventType }
    | { "Sender": SuiAddress }
    | { "Recipient": ObjectOwner }
    | { "Object": ObjectId }
    | { "TimeRange": { "start_time": number, "end_time": number } };
*/

export const getUserCoins = async (type = SUI) => {
  const userObjects = await getUserObjects({
    filter: {
      StructType: `${SuiAddress}::coin::Coin<${type}>`,
    },
  });
  let largest = 0;
  const items = [];
  userObjects.data.forEach((item) => {
    items.push(item.id);
  });
  let suiObjects = (await getObjectsInfo(items)).filter((a) => a);
  let total = 0;
  suiObjects = suiObjects.map(({ id, data: { balance } }) => {
    const intBalance = parseInt(balance);
    total += intBalance;
    if (intBalance > largest) {
      largest = intBalance;
    }
    return { id, data: { balance: intBalance } };
  });
  return { total, largest, suiObjects };
};

/*const generateSkipIds = (args) => {
  const skipObjectIds = [];
  args.forEach((a) => {
    if (typeof a === "string" && isValidSuiObjectId(a)) {
      skipObjectIds.push(a);
    } else if (Array.isArray(a)) {
      a.forEach((b) => {
        if (typeof b === "string" && isValidSuiObjectId(b)) {
          skipObjectIds.push(b);
        }
      });
    }
  });
  return skipObjectIds;
};

const getPaymentCoin = (amount, suiObjects, args = []) => {
  let diff = Number.MAX_SAFE_INTEGER;
  let coinObject = false;
  const skipList = generateSkipIds(args);
  suiObjects.forEach(({ id, data }) => {
    if (skipList.includes(id)) {
      return;
    }
    const amnt = parseInt(data.balance);
    const thisDiff = amnt - amount;
    if (thisDiff >= 0 && thisDiff < diff) {
      coinObject = id;
      diff = thisDiff;
    }
  });
  return coinObject;
};

const getLargestCoin = async (suiObjects, args = []) => {
  let max = 0;
  let coinObject = false;
  const skipObjectIds = generateSkipIds(args);

  suiObjects.forEach((coin) => {
    if (skipObjectIds.includes(coin.id)) {
      return;
    }
    const amnt = parseInt(coin.data.balance);
    if (max < amnt) {
      max = amnt;
      coinObject = coin;
    }
  });
  return [coinObject, max];
};*/

export const getObjectByType = (types, from, notTypes) => {
  const item = from.find((item) => filter(item.type, types, notTypes));
  if (item) {
    return item;
  }
  return false;
};

export const getObjectsByType = (types, from = false, notTypes) => {
  const items = from.filter((item) => filter(item.type, types, notTypes));
  if (items) {
    return items;
  }
  return false;
};

export const getObjectsByObjectType = (types, from, notTypes) => {
  return from.filter((item) => filter(item.objectType, types, notTypes));
};

export const mergeCoins = async (coins) =>
  promiseTransact({
    target: `${SuiAddress}::coin::join`,
    typeArguments: [SUI],
    types: ["object", "object"],
    arguments: coins,
  });

export const makeNftListing = (nft, collection, price, userKiosks) => {
  let realPrice = suiToMyst(price);
  let txns = [];
  if (collection.kiosk_enabled) {
    txns = listKiosk(nft, realPrice, userKiosks);
    return signAndExecuteTransactionBlock(txns);
  } else if (collection.ob_enabled) {
    txns = listOB(nft, collection, realPrice, userKiosks);
  } else {
    txns = listNonstandard(nft, collection, realPrice);
  }
  return promiseTransact(txns);
};

export const buyNftListing = async (listing, price, userKiosks, options) => {
  let txns = [];

  if (listing.seller_kiosk) {
    if (listing.nft_collection.kiosk_enabled) {
      txns = await buyKiosk(listing, price, userKiosks);
      return signAndExecuteTransactionBlock(txns);
    } else if (listing.nft_collection.ob_enabled) {
      txns = buyOB(listing, price, userKiosks);
    }
  } else {
    txns = buyNonstandard(listing, price);
  }
  return promiseTransact(txns, options);
};

export const makeNftAuction = async (
  nft,
  collection,
  { min_bid, min_bid_increment, starts, expires },
  userKiosks
) => {
  let txns = [];
  const truePrice = suiToMyst(min_bid).toString();
  const realBidIncrement = suiToMyst(min_bid_increment).toString();
  let formattedStarts = new Date(starts).getTime().toString();
  let formattedExpires = new Date(expires).getTime().toString();

  if (collection.kiosk_enabled) {
    let marketInfo = await getObjectInfo(settings.mysten_market.marketplace);
    txns = auctionKiosk(
      marketInfo,
      nft,
      truePrice,
      realBidIncrement,
      formattedStarts,
      formattedExpires,
      userKiosks
    );
    return signAndExecuteTransactionBlock(txns);
  } else if (collection.ob_enabled) {
    let marketInfo = await getObjectInfo(settings.market.ob_marketplace);
    txns = auctionOB(
      marketInfo,
      nft,
      collection,
      truePrice,
      realBidIncrement,
      formattedStarts,
      formattedExpires,
      userKiosks
    );
  } else {
    let marketInfo = await getObjectInfo(settings.market.marketplace);
    txns = auctionNonstandard(
      marketInfo,
      nft,
      collection,
      truePrice,
      realBidIncrement,
      formattedStarts,
      formattedExpires
    );
  }

  return promiseTransact(txns);
};

export const bidNFTAuction = async (auction, price) => {
  const truePrice = suiToMyst(price);
  let txns = Array.of({
    type: "splitCoins",
    amounts: [truePrice],
  });
  if (auction.seller_kiosk) {
    if (auction.nft_collection.kiosk_enabled) {
      txns.push({
        target: `${settings.packages.keepsake_kiosk_marketplace}::keepsake_kiosk_marketplace::bid`,
        typeArguments: [auction.object_type],
        arguments: [
          settings.mysten_market.marketplace,
          auction.nft_object_id,
          { txIndex: 0, index: 0 },
          truePrice.toString(),
          SUI_CLOCK_OBJECT_ID,
          [],
        ],
        types: ["object", "id", "object", "number", "object", "option<id>"],
      });
    } else {
      txns.push({
        target: `${settings.packages.keepsake_ob}::keepsake_ob_marketplace::bid`,
        typeArguments: [auction.object_type],
        arguments: [
          settings.market.ob_marketplace,
          auction.nft_object_id,
          { txIndex: 0, index: 0 },
          truePrice.toString(),
          SUI_CLOCK_OBJECT_ID,
        ],
        types: ["object", "address", "object", "number", "object"],
      });
    }
  } else {
    txns.push({
      target: `${settings.packages.keepsake}::keepsake_marketplace::bid`,
      typeArguments: [auction.object_type],
      arguments: [
        settings.market.marketplace,
        auction.nft_object_id,
        { txIndex: 0, index: 0 },
        truePrice.toString(),
        SUI_CLOCK_OBJECT_ID,
      ],
      types: ["object", "address", "object", "number", "object"],
    });
  }

  return promiseTransact(txns);
};

export const winNFTAuction = async (listing, userKiosks) => {
  let txns = [];

  if (listing.seller_kiosk) {
    if (listing.nft_collection.kiosk_enabled) {
      txns = await winKioskAuction(listing, userKiosks);
      return signAndExecuteTransactionBlock(txns);
    } else if (listing.nft_collection.ob_enabled) {
      txns = winOBAuction(listing, userKiosks);
    }
  } else {
    txns = winNonstandardAuction(listing);
  }
  return promiseTransact(txns);
};

export const unlistNFT = async (listing) => {
  if (listing.seller_kiosk && listing.nft_collection.kiosk_enabled) {
    if (listing.sale_type === "auction") {
      return promiseTransact({
        target: `${settings.packages.keepsake_kiosk_marketplace}::keepsake_kiosk_marketplace::deauction`,
        typeArguments: [listing.object_type],
        arguments: [
          settings.mysten_market.marketplace,
          listing.nft_object_id,
          SUI_CLOCK_OBJECT_ID,
          listing.seller_kiosk,
        ],
        types: ["object", "id", "object", "object"],
      });
    } else {
      return promiseTransact({
        target: `${settings.packages.keepsake_kiosk_marketplace}::keepsake_kiosk_marketplace::delist`,
        typeArguments: [listing.object_type],
        arguments: [
          settings.mysten_market.marketplace,
          listing.seller_kiosk,
          listing.nft_object_id,
        ],
        types: ["object", "object", "id"],
      });
    }
  } else if (listing.seller_kiosk && listing.nft_collection.ob_enabled) {
    if (listing.sale_type === "auction") {
      return promiseTransact({
        target: `${settings.packages.keepsake_ob}::keepsake_ob_marketplace::deauction`,
        typeArguments: [listing.object_type],
        arguments: [
          settings.market.ob_marketplace,
          listing.nft_object_id,
          SUI_CLOCK_OBJECT_ID,
          listing.seller_kiosk,
        ],
        types: ["object", "address", "object", "object"],
      });
    } else {
      return promiseTransact({
        target: `${settings.packages.ob_liquidity_layer_v1}::orderbook::cancel_ask`,
        typeArguments: [listing.object_type, SUI],
        arguments: [
          listing.orderbook,
          listing.seller_kiosk,
          listing.sale_price,
          listing.nft_object_id,
        ],
        types: ["object", "object", "u64", "id"],
      });
    }
  } else {
    if (listing.sale_type === "auction") {
      return promiseTransact({
        function: "deauction",
        typeArguments: [listing.object_type],
        arguments: [
          settings.market.marketplace,
          settings.market.kiosk,
          listing.nft_object_id,
          SUI_CLOCK_OBJECT_ID,
        ],
        types: ["object", "object", "address", "object"],
      });
    } else {
      return promiseTransact({
        function: "delist_and_take",
        typeArguments: [listing.object_type],
        arguments: [
          settings.market.marketplace,
          settings.market.kiosk,
          listing.nft_object_id,
        ],
        types: ["object", "object", "address"],
      });
    }
  }
};

// Kiosks
export const createOBKiosk = (forTx = false) => {
  if (forTx) {
    return {
      target: `${settings.packages.ob_kiosk}::ob_kiosk::new_for_address`,
      arguments: [userAddress],
      types: ["address"],
    };
  }

  return promiseTransact({
    target: `${settings.packages.ob_kiosk}::ob_kiosk::create_for_sender`,
    arguments: [],
    types: [],
  });
};

export const createKiosk = (forTx = false, txns, existing_kiosk) => {
  if (settings.object_ids.personal_kiosk) {
    return createPersonalKiosk(forTx, txns, existing_kiosk);
  }

  if (forTx) {
    return (
      [
        {
          target: `${SuiAddress}::kiosk::new`,
          arguments: [],
          types: [],
        },
      ],
      {
        id: { txIndex: txns?.length || 0, index: 0 },
        cap: { txIndex: txns?.length || 0, index: 1 },
      }
    );
  }

  return promiseTransact({
    target: `${SuiAddress}::kiosk::default`,
    arguments: [],
    types: [],
  });
};

export const shareKiosk = (txns, newUserKiosk, cap) => {
  if (settings.object_ids.personal_kiosk) {
    return receivePersonalKiosk(txns, newUserKiosk, cap);
  } else {
    return [
      {
        target: `${SuiAddress}::transfer::public_share_object`,
        typeArguments: [`${SuiAddress}::kiosk::Kiosk`],
        arguments: [{ txIndex: newUserKiosk, index: 0 }],
        types: ["object"],
      },
      {
        type: "transferObjects",
        object: cap,
        to: userAddress,
      },
    ];
  }
};

const createPersonalKiosk = (forTx, txns, kiosk) => {
  let new_txns = [];
  let tx_length = txns?.length || 0;
  let useKiosk = kiosk || {};

  if (!kiosk) {
    new_txns.push({
      target: `${SuiAddress}::kiosk::new`,
    });
    useKiosk.id = { txIndex: tx_length, index: 0 };
    useKiosk.cap = { txIndex: tx_length, index: 1 };
  }

  new_txns.push({
    target: `${settings.packages.mysten_kiosk}::personal_kiosk::new`,
    arguments: [useKiosk.id, useKiosk.cap],
    types: ["object", "object"],
  });
  let personal_kiosk = { txIndex: new_txns?.length - 1, index: 0 };

  if (forTx) {
    return [new_txns, { id: useKiosk.id, cap: { txIndex: tx_length, index: 0 } }];
  } else {
    if (!kiosk) {
      new_txns.push({
        target: `${SuiAddress}::transfer::public_share_object`,
        typeArguments: [`${SuiAddress}::kiosk::Kiosk`],
        arguments: [useKiosk.id],
        types: ["object"],
      });
    }
    new_txns.push({
      target: `${settings.packages.mysten_kiosk}::personal_kiosk::transfer_to_sender`,
      arguments: [personal_kiosk],
      types: ["object"],
    });
    return promiseTransact(new_txns);
  }
};

const receivePersonalKiosk = (txns, newUserKiosk, personal_cap) => {
  let new_txns = [];
  if (newUserKiosk) {
    new_txns.push({
      target: `${SuiAddress}::transfer::public_share_object`,
      typeArguments: [`${SuiAddress}::kiosk::Kiosk`],
      arguments: [newUserKiosk],
      types: ["object"],
    });
    new_txns.push({
      target: `${settings.packages.mysten_kiosk}::personal_kiosk::transfer_to_sender`,
      arguments: [personal_cap],
      types: ["object"],
    });
  } else {
    new_txns.push({
      target: `${settings.packages.mysten_kiosk}::personal_kiosk::transfer_to_sender`,
      arguments: [personal_cap],
      types: ["object"],
    });
  }
  return new_txns;
};

// Minting
export const makeMintTx = ({
  name,
  description,
  image,
  metadata,
  nft_collection,
  type,
  count,
  launchpad,
  tier,
  recipient,
}) => {
  let functionName = count > 1 ? "mint_many" : "mint";
  const attribute_keys = metadata ? Object.keys(metadata) : [];
  const attribute_values = metadata
    ? Object.keys(metadata).map((key) => metadata[key].toString())
    : [];
  let args = [
    name,
    description,
    image,
    attribute_keys,
    attribute_values,
    nft_collection.mint_object_id,
  ];
  let types = ["string", "string", "string", "vec<vec<u8>>", "vec<vec<u8>>", "object"];
  switch (type) {
    // TODO: Make launchpad 2 txns
    case "list":
    case "auction":
      if (count > 1) {
        args.push(count);
        types.push("number");
      }
      break;
    case "launchpad":
      functionName = "mint_launchpad";
      args = args.concat([launchpad.object_id, tier.object_id, count || 1]);
      types = types.concat(["object", "object", "number"]);
      break;
    case "nft_bag":
      break;
    default:
      functionName = "mint_to";
      args = args.concat([recipient || userAddress, count || 1]);
      types = types.concat(["address", "number"]);
      break;
  }
  return {
    target: `${nft_collection.object_id}::${nft_collection.module_name}::${functionName}`,
    arguments: args,
    types: types,
  };
};

export const mintNFT = async (
  {
    nft_collection,
    tier,
    recipient,
    count,
    launchpad,
    name,
    description,
    image,
    metadata,
  },
  type
) => {
  let mintTx = makeMintTx({
    name,
    description,
    image: image,
    metadata,
    nft_collection,
    type,
    count,
    tier,
    launchpad,
    recipient,
  });
  let txns = [];

  if (type == "nft_bag") {
    const txb = makeTxnBlock();
    let [nft] = addToTransaction(txb, mintTx);
    mintToBag(nft, nft_collection, tier, txb);
    return signAndExecuteTransactionBlock(txb);
  }
  txns.push(mintTx);

  return promiseTransact(txns);
};

export const mintNFTs = async (
  { collection: nft_collection, tier, recipient, launchpad },
  nfts,
  type
) => {
  let txns = [];
  if (type == "nft_bag") {
    const txb = makeTxnBlock();
    nfts.forEach((nft) => {
      let { name, description, image, url, attributes } = nft;
      let mintTx = makeMintTx({
        name,
        description,
        image: image || url,
        metadata: attributes,
        nft_collection,
        type,
        tier,
        launchpad,
        recipient,
      });
      let [nft_ref] = addToTransaction(txb, mintTx);
      mintToBag(nft_ref, nft_collection, tier, txb);
    });
    return signAndExecuteTransactionBlock(txb);
  } else {
    nfts.forEach((nft) => {
      let { name, description, image, url, attributes } = nft;
      let mintTx = makeMintTx({
        name,
        description,
        image: image || url,
        metadata: attributes,
        nft_collection,
        type,
        tier,
        launchpad,
        recipient,
      });
      txns.push(mintTx);
    });

    return promiseTransact(txns);
  }
};

export const transferNFT = async (nft, to) => {
  let realNFT =
    typeof nft == "string"
      ? await getObjectInfo(nft, { showType: true, showOwner: true })
      : realNFT;
  promiseTransact({
    type: "transferObjects",
    object: realNFT.id,
    to: to,
  });
};

// For Collection creation
export const publish = (data, dependencies) =>
  promiseTransact({
    compiledModules: data || settings.nft_contract.modules,
    dependencies: dependencies || settings.nft_contract.dependencies,
    type: "publish",
  });

export const upgrade = (upgrade_cap, { data, dependencies, digest } = {}) =>
  promiseTransact([
    {
      target: `${SuiAddress}::package::authorize_upgrade`,
      types: ["object", "u8", "vec<u8>"],
      arguments: [
        upgrade_cap.fields.id.id,
        upgrade_cap.fields.policy,
        digest || settings.nft_contract.digest,
      ],
    },
    {
      compiledModules: data || settings.nft_contract.modules,
      dependencies: dependencies || settings.nft_contract.dependencies,
      packageId: upgrade_cap.fields.package,
      ticket: { txIndex: 0, index: 0 },
      type: "upgrade",
    },
    {
      target: `${SuiAddress}::package::commit_upgrade`,
      types: ["object", "object"],
      arguments: [upgrade_cap.fields.id.id, { txIndex: 1, index: 0 }],
    },
  ]);

export const setupKeepsakeRoyalties = async (collection) => {
  let typeOfCollection = collection.full_type;
  const txns = [
    {
      target: `${settings.packages.keepsake}::transfer_policy::admin_new`,
      typeArguments: [typeOfCollection],
      types: ["object"],
      arguments: [settings.market.tp_admin],
    },
    {
      target: `${settings.packages.keepsake}::keepsake_royalties::create_and_add_strategy`,
      typeArguments: [typeOfCollection],
      types: ["object", "object", "u64"],
      arguments: [
        { txIndex: 0, index: 0 },
        { txIndex: 0, index: 1 },
        collection.royaltyFee.toString(),
      ],
    },
    // send cap to owner
    {
      target: `${SuiAddress}::transfer::public_share_object`,
      typeArguments: [
        `${settings.packages.keepsake}::transfer_policy::TransferPolicy<${typeOfCollection}>`,
      ],
      arguments: [{ txIndex: 0, index: 0 }],
      types: ["object"],
    },
    {
      type: "transferObjects",
      object: { txIndex: 0, index: 1 },
      to: collection.creator.account_address,
    },
  ];
  return promiseTransact(txns);
};

export const createCollection = async (
  packageObjectId,
  name,
  desc,
  tags = [],
  fee = "500",
  creation_tx,
  standard
) => {
  let txResults = await getTransaction(creation_tx);
  const created = txResults.effects.created.map((item) => item.reference.objectId);
  let known = await getObjectsInfo(created, { showType: true, showContent: true });

  let collection = getObjectByType("collection::Collection", known);
  let transfer_policy = getObjectByType(
    `${settings.object_ids.keepsake.slice(10)}::transfer_policy::TransferPolicy<`,
    known,
    "dynamic_field"
  );
  let transfer_policy_cap = getObjectByType(
    `${settings.object_ids.keepsake.slice(10)}::transfer_policy::TransferPolicyCap<`,
    known,
    "dynamic_field"
  );

  let policies = getObjectsByType(`2::transfer_policy::TransferPolicy<`, known);
  let policyCaps = getObjectsByType(`2::transfer_policy::TransferPolicyCap<`, known);

  let ob_policy = policies.find((a) =>
    a.data.rules.fields.contents[0].fields.name.includes(
      "transfer_allowlist::AllowlistRule"
    )
  );

  let ob_policyCap = policyCaps.find((a) => a.data.policy_id === ob_policy.id);
  let publisher = getObjectByType("2::package::Publisher", known);
  let mint_object = getObjectByType("::mint_cap::MintCap", known);
  let collectionType = `${packageObjectId}::keepsake_nft::KEEPSAKE`;

  let txns = [
    {
      packageObjectId,
      module: "keepsake_nft",
      function: "create",
      arguments: [
        name,
        desc,
        // tags,
        fee.toString(),
        collection.id,
        transfer_policy.id,
        transfer_policy_cap.id,
      ],
      types: ["string", "string", /*"vec",*/ "u64", "object", "object", "object"],
    },
  ];

  if (standard === "originbyte") {
    txns = txns.concat([
      {
        target: `${packageObjectId}::keepsake_nft::getDelegatedWitness`,
        arguments: [mint_object.id],
        types: ["object"],
      },
      {
        target: `${settings.packages.nft_protocol}::royalty_strategy_bps::enforce`,
        typeArguments: [collectionType],
        arguments: [ob_policy.id, ob_policyCap.id],
        types: ["object", "object"],
      },
      {
        target: `${settings.packages.ob_liquidity_layer_v1}::orderbook::create_unprotected`,
        typeArguments: [collectionType, SUI],
        arguments: [{ txIndex: 1, index: 0 }, ob_policy.id],
        types: ["object", "object"],
      },
      {
        target: `${settings.packages.ob_allowlist}::allowlist::insert_collection`,
        typeArguments: [collectionType],
        arguments: [settings.market.ob_allowlist, publisher.id],
        types: ["object", "object"],
      },
      {
        target: `${settings.packages.ob_allowlist}::allowlist::insert_collection`,
        typeArguments: [collectionType],
        arguments: [settings.market.auction_allowlist, publisher.id],
        types: ["object", "object"],
      },
    ]);
  } else if (standard === "mysten") {
    txns = txns.concat([
      {
        target: `${SuiAddress}::transfer_policy::new`,
        typeArguments: [collectionType],
        arguments: [publisher.id],
        types: ["object"],
      },
      {
        target: `${settings.packages.mysten_kiosk}::kiosk_lock_rule::add`,
        typeArguments: [collectionType],
        arguments: [
          { txIndex: 1, index: 0 },
          { txIndex: 1, index: 1 },
        ],
        types: ["object", "object"],
      },
      {
        target: `${settings.packages.mysten_kiosk}::royalty_rule::add`,
        typeArguments: [collectionType],
        arguments: [
          { txIndex: 1, index: 0 },
          { txIndex: 1, index: 1 },
          fee.toString(),
          0,
        ],
        types: ["object", "object", "u16", "u64"],
      },
      {
        target: `${SuiAddress}::transfer::public_share_object`,
        typeArguments: [
          `${SuiAddress}::transfer_policy::TransferPolicy<${collectionType}>`,
        ],
        arguments: [{ txIndex: 1, index: 0 }],
        types: ["object"],
      },
      {
        type: "transferObjects",
        object: { txIndex: 1, index: 1 },
        to: userAddress,
      },
    ]);
  }

  return promiseTransact(txns);
};

export const addRoyalties = async (
  publisher,
  collectionType,
  royalties = "500",
  transfer_policy,
  transfer_policy_cap
) => {
  let txns = [];
  if (transfer_policy && transfer_policy_cap) {
    txns = [
      {
        target: `${settings.packages.keepsake}::keepsake_royalties::create_and_add_strategy`,
        typeArguments: [collectionType],
        arguments: [
          { txIndex: 0, index: 0 },
          { txIndex: 0, index: 1 },
        ],
        types: ["object", "object"],
      },
    ];
  } else {
    txns = [
      {
        target: `${settings.packages.keepsake}::transfer_policy::new`,
        typeArguments: [collectionType],
        arguments: [publisher],
        types: ["object"],
      },
      {
        target: `${settings.packages.keepsake}::keepsake_royalties::create_and_add_strategy`,
        typeArguments: [collectionType],
        arguments: [{ txIndex: 0, index: 0 }, { txIndex: 0, index: 1 }, royalties],
        types: ["object", "object", "number"],
      },
      {
        type: "transferObjects",
        object: { txIndex: 0, index: 1 },
        to: userAddress,
      },
      {
        target: `${SuiAddress}::transfer::public_share_object`,
        typeArguments: [
          `${settings.packages.keepsake}::transfer_policy::TransferPolicy<${collectionType}>`,
        ],
        arguments: [{ txIndex: 0, index: 0 }],
        types: ["object"],
      },
    ];
  }
  return promiseTransact(txns);
};

export const getRoyalties = async (collection, royaltyIndex = 0) => {
  let royaltInfo = false;
  if (collection.ob_enabled) {
    // royaltInfo = await getObjectInfo(collection.ob_royalties[royaltyIndex].id);
    return 100;
  } else {
    royaltInfo = await getObjectInfo(collection.royalties[royaltyIndex].id);
  }
  return royaltInfo?.data?.balance;
};

export const collectRoyalties = async (collection, royaltyIndex = 0) => {
  let txns = [
    {
      target: `${settings.packages.keepsake}::keepsake_royalties::collect_royalties`,
      typeArguments: [collection.full_type],
      arguments: [collection.royalties[royaltyIndex].id, collection.transfer_policy_cap],
      types: ["object", "object"],
    },
  ];
  if (collection.ob_enabled) {
    txns = [
      {
        target: `${settings.packages.nft_protocol}::royalty_strategy_bps::collect_royalties`,
        typeArguments: [collection.full_type, SUI],
        arguments: [
          collection.collection_object_id,
          collection.ob_royalties[royaltyIndex].id,
        ],
        types: ["object", "object"],
      },
    ];
  } else if (collection.kiosk_enabled) {
    const policies = await getTransferPolicies(collection.full_type);
    let filteredPolicies = policies.filter((policy) =>
      policy.rules.find((a) => a.includes("royalty_rule::Rule"))
    );
    // let caps = await queryOwnedTranferPolicyCap()
    let caps = await getUserObjects({
      filter: {
        StructType: `${SuiAddress}::transfer_policy::TransferPolicyCap`,
      },
      options: { showContent: true },
    });

    // TODO: handle multiples???
    let policy = filteredPolicies[0];
    let cap = caps.data.find((a) => a.data.policy_id == policy.id);
    txns = [
      {
        target: `${SuiAddress}::transfer_policy::withdraw`,
        typeArguments: [collection.full_type],
        arguments: [policy.id, cap.id, []],
        types: ["object", "object", "Option<u64>"],
      },
      {
        type: "transferObjects",
        object: { txIndex: 0, index: 0 },
        to: userAddress,
      },
    ];
  }
  promiseTransact(txns);
};

/* OriginByte addCollection options */
export const isInAllowlist = async (allowlist, type) => {
  const txb = makeTransactionBlock(
    [
      {
        target: `0x1::type_name::get`,
        typeArguments: [type],
        arguments: [],
        types: [],
      },
      {
        target: `${settings.packages.ob_allowlist}::allowlist::contains_collection`,
        arguments: [allowlist, { txIndex: 0, index: 0 }],
        types: ["object", "object"],
      },
    ],
    userAddress
  );
  const data = await devInspectTransaction(userAddress, txb);
  return data?.results?.[1]?.returnValues[0][0][0] === 1;
};

export const addToAllowlist = (allowlist, type, publisher) =>
  promiseTransact({
    target: `${settings.packages.ob_allowlist}::allowlist::insert_collection`,
    typeArguments: [type],
    arguments: [allowlist, publisher],
    types: ["object", "object"],
  });

export const enableOriginByte = async (collection) => {
  let [events, isAllowed] = await Promise.all([
    getEvents({
      MoveEventType: `${SuiAddress}::transfer_policy::TransferPolicyCreated<${collection.full_type}>`,
    }),
    isInAllowlist(settings.market.ob_allowlist, collection.full_type),
  ]);
  let txDigest = events.data[0].id.txDigest;
  let txResults = await getTransaction(txDigest);
  const created = txResults.effects.created.map((item) => item.reference.objectId);
  const createdInfo = await getObjectsInfo(created, {
    showContent: true,
    showType: true,
  });

  let policies = getObjectsByType(`2::transfer_policy::TransferPolicy<`, createdInfo);
  let policyCaps = getObjectsByType(
    `2::transfer_policy::TransferPolicyCap<`,
    createdInfo
  );

  let policy = policies.find((a) =>
    a.data.rules.fields.contents[0].fields.name.includes(
      "transfer_allowlist::AllowlistRule"
    )
  );
  let policyCap = policyCaps.find((a) => a.data.policy_id === policy.id);

  let txns = [
    {
      target: `${collection.object_id}::keepsake_nft::getDelegatedWitness`,
      arguments: [collection.mint_object_id],
      types: ["object"],
    },
    {
      target: `${settings.packages.nft_protocol}::royalty_strategy_bps::enforce`,
      typeArguments: [collection.full_type],
      arguments: [policy.id, policyCap.id],
      types: ["object", "object"],
    },
    {
      target: `${settings.packages.ob_liquidity_layer_v1}::orderbook::create_unprotected`,
      typeArguments: [collection.full_type, SUI],
      arguments: [{ txIndex: 0, index: 0 }, policy.id],
      types: ["object", "object"],
    },
  ];
  if (!isAllowed) {
    txns.push({
      target: `${settings.packages.ob_allowlist}::allowlist::insert_collection`,
      typeArguments: [collection.full_type],
      arguments: [settings.market.ob_allowlist, collection.publisher_object_id],
      types: ["object", "object"],
    });
    txns.push({
      target: `${settings.packages.ob_allowlist}::allowlist::insert_collection`,
      typeArguments: [collection.full_type],
      arguments: [settings.market.auction_allowlist, collection.publisher_object_id],
      types: ["object", "object"],
    });
  }

  return promiseTransact(txns);
};

export const enableKiosk = async (collection) =>
  promiseTransact([
    {
      target: `${SuiAddress}::transfer_policy::new`,
      typeArguments: [collection.full_type],
      arguments: [collection.publisher_object_id],
      types: ["object"],
    },
    {
      target: `${settings.packages.mysten_kiosk}::kiosk_lock_rule::add`,
      typeArguments: [collection.full_type],
      arguments: [
        { txIndex: 0, index: 0 },
        { txIndex: 0, index: 1 },
      ],
      types: ["object", "object"],
    },
    {
      target: `${settings.packages.mysten_kiosk}::royalty_rule::add`,
      typeArguments: [collection.full_type],
      arguments: [
        { txIndex: 0, index: 0 },
        { txIndex: 0, index: 1 },
        collection.royaltyFee?.toString() || "500",
        0,
      ],
      types: ["object", "object", "u16", "u64"],
    },
    {
      target: `${SuiAddress}::transfer::public_share_object`,
      typeArguments: [
        `${SuiAddress}::transfer_policy::TransferPolicy<${collection.full_type}>`,
      ],
      arguments: [{ txIndex: 0, index: 0 }],
      types: ["object"],
    },
    {
      type: "transferObjects",
      object: { txIndex: 0, index: 1 },
      to: userAddress,
    },
  ]);

/**
 * Launchpad functions
 */
export const createLaunchpad = async () =>
  promiseTransact({
    target: `${settings.packages.v1_launchpad}::listing::init_listing`,
    arguments: [userAddress, userAddress],
    types: ["address", "address"],
  });

export const sendToLaunchpad = async (launchpad, tierIndex, nfts) => {
  let txns = [];
  nfts.forEach((nft) => {
    txns.push({
      target: `${settings.packages.v1_launchpad}::listing::add_nft`,
      typeArguments: [launchpad.launchpad_collection.full_type],
      arguments: [launchpad.object_id, launchpad.sales[tierIndex].object_id, nft],
      types: ["object", "address", "object"],
    });
  });
  return promiseTransact(txns);
};

export const updateLaunchpadListings = async (launchpad, inventories, values) => {
  let txns = [];
  if (launchpad.sales.length < values.sales.length) {
    const newSales = values.sales.slice(launchpad.sales.length);
    newSales.forEach((sale) => {
      let price = suiToMyst(sale.price || 0).toString();
      let limit = sale.limit || "1000000";
      let whitelisted = sale.whitelisted || false;
      let start_date = new Date(sale.start_date).getTime().toString();
      let end_date = new Date(sale.end_date).getTime().toString();
      if (sale.object_id) {
        txns.push({
          target: `${settings.packages.v1_launchpad}::limited_fixed_price::create_venue`,
          typeArguments: [launchpad.launchpad_collection.full_type, SUI],
          arguments: [
            launchpad.object_id,
            sale.object_id,
            whitelisted,
            limit,
            price,
            start_date,
            end_date,
          ],
          types: ["object", "address", "bool", "u64", "u64", "u64", "u64"],
        });
      } else {
        txns.push({
          target: `${settings.packages.v1_launchpad}::listing::create_warehouse`,
          typeArguments: [launchpad.launchpad_collection.full_type],
          arguments: [launchpad.object_id],
          types: ["object"],
        });
        txns.push({
          target: `${settings.packages.v1_launchpad}::limited_fixed_price::create_venue`,
          typeArguments: [launchpad.launchpad_collection.full_type, SUI],
          arguments: [
            launchpad.object_id,
            { txIndex: txns.length - 1, index: 0 },
            whitelisted,
            limit,
            price,
            start_date,
            end_date,
          ],
          types: ["object", "address", "bool", "u64", "u64", "u64", "u64"],
        });
      }
    });
  }
  const oldSales = values.sales.slice(0, launchpad.sales.length);
  oldSales.forEach((sale, index) => {
    let price = suiToMyst(sale.price || 0);
    let limit = sale.limit || "1000000";
    let start_date = new Date(sale.start_date).getTime().toString();
    let end_date = new Date(sale.end_date).getTime().toString();

    if (
      start_date !== inventories[index].start_date ||
      end_date !== inventories[index].end_date
    ) {
      txns.push({
        target: `${settings.packages.v1_launchpad}::listing::set_live`,
        arguments: [
          launchpad.object_id,
          launchpad.sales[index].venue_id,
          new Date(sale.start_date).getTime().toString(),
          new Date(sale.end_date).getTime().toString(),
        ],
        types: ["object", "object", "u64", "u64"],
      });
    }
    if (price !== launchpad.sales[index].price) {
      txns.push({
        target: `${settings.packages.v1_launchpad}::limited_fixed_price::set_price`,
        typeArguments: [SUI],
        arguments: [
          launchpad.object_id,
          launchpad.sales[index].venue_id,
          price.toString(),
        ],
        types: ["object", "object", "u64"],
      });
    }
    if (limit !== launchpad.sales[index].limit) {
      txns.push({
        target: `${settings.packages.v1_launchpad}::limited_fixed_price::set_limit`,
        typeArguments: [SUI],
        arguments: [
          launchpad.object_id,
          launchpad.sales[index].venue_id,
          limit.toString(),
        ],
        types: ["object", "object", "u64"],
      });
    }
    if (sale.whitelisted != inventories[index].is_whitelisted) {
      // TODO: once we add our own, set it to use a non-collection-specific function
      txns.push({
        target: `${settings.packages.v1_launchpad}::listing::set_whitelisted`,
        arguments: [
          launchpad.object_id,
          launchpad.sales[index].venue_id,
          sale.whitelisted,
        ],
        types: ["object", "object", "bool"],
      });
    }
  });
  if (txns.length > 0) {
    return promiseTransact(txns);
  } else {
    return Promise.resolve();
  }
};

export const issueWhitelist = (launchpad, tier, addresses) => {
  const txns = [];
  addresses.forEach((address) => {
    txns.push({
      target: `${settings.packages.v1_launchpad}::market_whitelist::issue`,
      arguments: [launchpad.object_id, launchpad.sales[tier].venue_id, address],
      types: ["object", "object", "address"],
    });
  });
  if (txns.length > 0) {
    return promiseTransact(txns);
  } else {
    return Promise.reject(new Error("No addresses to whitelist"));
  }
};

export const getLaunchpadUserLimit = async (launchpad, saleIndex) => {
  if (launchpad.sales[saleIndex].limit >= 1000000) {
    return 0;
  }
  const txns = [
    {
      target: `${settings.packages.v1_launchpad}::listing::borrow_venue`,
      arguments: [launchpad.object_id, launchpad.sales[saleIndex].venue_id],
      types: ["object", "address"],
    },
    {
      target: `${settings.packages.v1_launchpad}::limited_fixed_price::borrow_market`,
      arguments: [{ txIndex: 0, index: 0 }],
      typeArguments: [SUI],
      types: ["object", "address"],
    },
    {
      target: `${settings.packages.v1_launchpad}::limited_fixed_price::borrow_count`,
      arguments: [{ txIndex: 1, index: 0 }, userAddress],
      typeArguments: [`${SuiAddress}::sui::SUI`],
      types: ["object", "address"],
    },
  ];

  const txb = makeTransactionBlock(txns, userAddress);
  let res = await devInspectTransaction(userAddress, txb);
  let data = res?.results?.[2]?.returnValues[0];

  var length = data.length;
  let buffer = Buffer.from(data);
  var result = buffer.readUIntBE(0, length);

  return result;
};

export const getLaunchpadUserLimits = async (launchpad) => {
  let txns = [];
  launchpad.sales.forEach((sale, index) => {
    txns = txns.concat([
      {
        target: `${settings.packages.v1_launchpad}::listing::borrow_venue`,
        arguments: [launchpad.object_id, launchpad.sales[index].venue_id],
        types: ["object", "address"],
      },
      {
        target: `${settings.packages.v1_launchpad}::limited_fixed_price::borrow_market`,
        arguments: [{ txIndex: 0, index: 0 }],
        typeArguments: [`${SuiAddress}::sui::SUI`],
        types: ["object", "address"],
      },
      {
        target: `${settings.packages.v1_launchpad}::limited_fixed_price::borrow_count`,
        arguments: [{ txIndex: 1, index: 0 }, userAddress],
        typeArguments: [`${SuiAddress}::sui::SUI`],
        types: ["object", "address"],
      },
    ]);
  });

  const txb = makeTransactionBlock(txns, userAddress);
  let res = await devInspectTransaction(userAddress, txb);
  let data = res?.results?.filter((value, index) => {
    return (index + 1) % 3 == 0;
  });
  var results = [];
  data.forEach((limit) => {
    var length = limit?.returnValues[0].length;
    let buffer = Buffer.from(data);
    results.push(buffer.readUIntBE(0, length));
  });

  return results;
};

export const buyLaunchpadNFT = async (
  launchpad,
  saleIndex,
  whitelist_tokens = false,
  quantity = 1
) => {
  const txns = [];
  const col = launchpad.launchpad_collection;
  const listing = launchpad.object_id;
  const { venue_id } = launchpad.sales[saleIndex];
  const price = launchpad.sales[saleIndex].price.toString();

  // get a coin of the right amount
  txns.push({
    type: "splitCoins",
    // coin: coin,
    amounts: [price * quantity],
  });
  for (let index = 0; index < quantity; index++) {
    let args = {};
    args.target = `${settings.packages.v1_launchpad}::limited_fixed_price::buy_nft`;
    args.arguments = [listing, venue_id, { txIndex: 0, index: 0 }, SUI_CLOCK_OBJECT_ID];
    if (whitelist_tokens) {
      args.target = `${settings.packages.v1_launchpad}::limited_fixed_price::buy_whitelisted_nft`;
      args.arguments.push(whitelist_tokens[index].id);
    }
    args.types = ["object", "object", "address", "object", "object"];
    args.typeArguments = [`${col.object_id}::${col.module_name}::${col.nft_name}`, SUI];
    txns.push(args);
  }

  // clean up coin afterwards
  txns.push({
    type: "transferObjects",
    object: { txIndex: 0, index: 0 },
    to: userAddress,
  });

  return promiseTransact(txns);
};

export const withdrawLaunchpadNFT = (launchpad, tier, ids) => {
  let txns = [];
  if (Array.isArray(ids)) {
    ids.forEach((id) => {
      txns.push({
        target: `${settings.packages.v1_launchpad}::listing::withdraw_by_id`,
        typeArguments: [launchpad.launchpad_collection.full_type],
        arguments: [launchpad.object_id, launchpad.sales[tier].object_id, id],
        types: ["object", "object", "address"],
      });
    });
  } else {
    let i = 0;
    while (i < ids) {
      txns.push({
        target: `${settings.packages.v1_launchpad}::listing::withdraw_one`,
        typeArguments: [launchpad.launchpad_collection.full_type],
        arguments: [launchpad.object_id, launchpad.sales[tier].object_id],
        types: ["object", "object"],
      });
      i++;
    }
  }
  return promiseTransact(txns);
};

export const getSaleData = async (
  launchpad,
  inventories = [],
  saleIndex = 0,
  getLimits = false
) => {
  try {
    if (launchpad) {
      if (inventories.length === 0 && launchpad.sales.length > 1) {
        const ownedObjectsPromises = [];
        for (let index = 0; index < launchpad.sales.length; index++) {
          ownedObjectsPromises.push(
            getObjectsOwnedByObject(launchpad.sales[index].object_id)
          );
        }
        let saleData = await Promise.all(ownedObjectsPromises);

        const warehouses = saleData.map(
          ({ data: saleObjects }) =>
            saleObjects.find((a) => a.name.type.includes("::Warehouse")).objectId
        );

        let uniq = [...new Set(warehouses)];
        let warehouseLength = uniq.length;

        let warehousesAndVenues = uniq.concat(launchpad.sales.map((i) => i.venue_id));

        uniq = [...new Set(uniq)];

        const allInfo = await getObjectsInfo(warehousesAndVenues);
        let venues = allInfo.slice(warehouseLength);
        let warehouseInfo = allInfo.slice(0, warehouseLength);

        const newInventories = [...inventories];
        for (let index = 0; index < launchpad.sales.length; index++) {
          let venue = venues.find((a) => a.id === launchpad.sales[index].venue_id);
          let warehouse = warehouseInfo.find((a) => a.id === warehouses[index]);

          newInventories[index] = {
            current: warehouse.data.value.fields.total_deposited,
            total: warehouse.data.value.fields.max_deposited,
            start_date: venue.data.start_date,
            end_date: venue.data.end_date,
            is_whitelisted: venue.data.is_whitelisted,
          };
        }
        if (getLimits) {
          try {
            let limits = await getLaunchpadUserLimits(launchpad);
            limits.forEach((limit, index) => {
              newInventories[index].user_limit = limit;
            });
          } catch (e) {
            console.log(e);
          }
        }

        return newInventories;
      } else if (launchpad.sales[saleIndex]) {
        const saleData = await getObjectsOwnedByObject(
          launchpad.sales[saleIndex].object_id
        );
        const warehouse = saleData.data.find((a) => a.name.type.includes("::Warehouse"));
        let [warehouseInfo, venue] = await getObjectsInfo([
          warehouse.objectId,
          launchpad.sales[saleIndex].venue_id,
        ]);

        const newInventories = [...inventories];
        newInventories[saleIndex] = {
          current: warehouseInfo.data.value.fields.total_deposited,
          total: warehouseInfo.data.value.fields.max_deposited,
          start_date: venue.data.start_date,
          end_date: venue.data.end_date,
          is_whitelisted: venue.data.is_whitelisted,
        };
        if (getLimits) {
          try {
            let limit = await getLaunchpadUserLimit(launchpad, saleIndex);
            newInventories[saleIndex].user_limit = limit;
          } catch {}
        }

        return newInventories;
      }
    }
  } catch (e) {
    console.log(e);
  }
};

//TODO: get proceeds. get_proceeds_amount<SUI>(listing)

export const sendProceeds = (launchpad) =>
  promiseTransact({
    target: `${settings.packages.v1_launchpad}::listing::collect_proceeds`,
    arguments: [launchpad.object_id, settings.market.marketplace],
    typeArguments: [SUI],
    types: ["object", "object"],
  });

// Lending
export const lendNFT = async (
  nft,
  collection,
  { price: price_per_hour, start_date, end_date, min_duration, max_duration },
  userKiosk
) => {
  let formattedStarts = new Date(start_date).getTime().toString();
  let formattedEnds = new Date(end_date).getTime().toString();
  // let in_safe = nft.owner !== userAddress;
  let data = {
    price: price_per_hour,
    formattedStarts,
    formattedEnds,
    min_duration,
    max_duration,
  };

  let txns = [];

  if (collection.kiosk_enabled) {
    const policies = await getTransferPolicies(nft.type);
    let filteredPolicies = policies.filter((policy) =>
      policy.rules.find((a) => a.includes("kiosk_lock_rule"))
    );
    let policy = filteredPolicies[0];
    txns = lendKioskNFT(nft, collection, data, policy, userKiosk);
    return signAndExecuteTransactionBlock(txns);
  } else if (collection.ob_enabled) {
    txns = lendOBNFT(nft, collection, userKiosk, data);
  }

  return promiseTransact(txns);
};

export const haltLendingNFT = async (listing) => {
  const txns = [];
  if (listing.nft_collection.kiosk_enabled) {
    txns.push({
      target: `${settings.packages.keepsake_kiosk_lending}::keepsake_kiosk_lending::stop_lending`,
      typeArguments: [listing.nft_collection.full_type],
      arguments: [listing.nft_object_id, settings.lending.marketplace],
      types: ["id", "object"],
    });
  } else if (listing.nft_collection.ob_enabled) {
    txns.push({
      target: `${settings.packages.keepsake_ob_lending}::keepsake_ob_lending::stop_lending`,
      typeArguments: [listing.nft_collection.full_type],
      arguments: [listing.nft_object_id, settings.lending.ob_marketplace],
      types: ["id", "object"],
    });
  }
  return promiseTransact(txns);
};

export const borrowNFT = async (listing, hours, kiosk) => {
  let nft = await getObjectInfo(listing.nft_object_id);
  let df = await getObjectInfo(nft.owner);
  let current_kiosk = df.owner;

  let txns = [];

  if (listing.nft_collection.kiosk_enabled) {
    const policies = await getTransferPolicies(nft.type);
    let filteredPolicies = policies.filter((policy) =>
      policy.rules.find((a) => a.includes("kiosk_lock_rule"))
    );
    let policy = filteredPolicies[0];
    txns = borrowKioskNFT(listing, current_kiosk, hours, policy, kiosk);
    return signAndExecuteTransactionBlock(txns);
  } else if (listing.nft_collection.ob_enabled) {
    txns = borrowOBNFT(listing, current_kiosk, hours, kiosk);
  }

  return promiseTransact(txns);
};

export const returnNFT = async (listing, forTx = false, { policy, kiosk = false }) => {
  if (!kiosk) {
    let nft = await getObjectInfo(listing.nft_object_id);
    let df = await getObjectInfo(nft.owner);
    kiosk = df.owner;
  }
  let txns = [];

  if (listing.nft_collection.kiosk_enabled) {
    txns = returnKioskNFT(listing, kiosk, policy);
  } else if (listing.nft_collection.ob_enabled) {
    txns = returnOBNFT(listing, kiosk, txns);
  }
  if (forTx) {
    return txns;
  }
  if (listing.nft_collection.kiosk_enabled) {
    return signAndExecuteTransactionBlock(txns);
  }
  return promiseTransact(txns);
};

export const getTransferPolicies = (type) => queryTransferPolicy(provider, type);

export const finishLendingNFT = async (listing, kiosk) => {
  let nft = await getObjectInfo(listing.nft_object_id, { showOwner: true });
  let df = await getObjectInfo(nft.owner, { showOwner: true });

  let txns = false;

  if (listing.nft_collection.ob_enabled) {
    if (df.owner !== settings.lending.ob_kiosk) {
      txns = await returnNFT(listing, true, { kiosk: df.owner });
    }
    txns = finishLendingOBNFT(listing, txns);
  } else if (listing.nft_collection.kiosk_enabled) {
    const policies = await getTransferPolicies(listing.object_type);
    let filteredPolicies = policies.filter((policy) =>
      policy.rules.find((a) => a.includes("kiosk_lock_rule"))
    );
    let policy = filteredPolicies[0];
    if (df.owner !== settings.lending.kiosk) {
      txns = await returnNFT(listing, true, { kiosk: df.owner, policy });
    }
    txns = finishLendingKioskNFT(listing, policy, kiosk, txns);
    return signAndExecuteTransactionBlock(txns);
  }
  return promiseTransact(txns);
};

/// Admin stuff

export const getMarketFees = async () => {
  let royaltInfo = await getObjectInfo(settings.market.marketplace);
  return royaltInfo?.data?.fee_balance;
};

export const withdrawMarketFees = (max = "18446744073709551615") =>
  promiseTransact({
    target: `${settings.packages.keepsake}::keepsake_marketplace::withdraw`,
    arguments: [settings.market.marketplace, userAddress, max],
    types: ["object", "address", "u64"],
  });

// Burning Keepsake NFTs
export const createBurnRule = async (collection) => {
  let txns = [];

  if (collection.kiosk_enabled) {
    txns = txns.concat([
      {
        target: `${SuiAddress}::transfer_policy::new`,
        typeArguments: [collection.full_type],
        arguments: [collection.publisher_object_id],
        types: ["object"],
      },
      {
        target: `${settings.packages.burn}::burn::add_strategy`,
        typeArguments: [collection.full_type],
        arguments: [
          { txIndex: 0, index: 0 },
          { txIndex: 0, index: 1 },
        ],
        types: ["object", "object"],
      },
      {
        target: `${SuiAddress}::transfer::public_share_object`,
        typeArguments: [
          `${SuiAddress}::transfer_policy::TransferPolicy<${collection.full_type}>`,
        ],
        arguments: [{ txIndex: 0, index: 0 }],
        types: ["object"],
      },
      {
        type: "transferObjects",
        object: { txIndex: 0, index: 1 },
        to: userAddress,
      },
    ]);
  } else {
    txns = txns.concat([
      {
        target: `${settings.packages.ob_request}::withdraw_request::init_policy`,
        typeArguments: [collection.full_type],
        arguments: [collection.publisher_object_id],
        types: ["object"],
      },
      {
        target: `${settings.packages.burn}::burn::add_ob_strategy`,
        typeArguments: [collection.full_type],
        arguments: [
          { txIndex: 0, index: 0 },
          { txIndex: 0, index: 1 },
        ],
        types: ["object", "object"],
      },
      {
        target: `${SuiAddress}::transfer::public_share_object`,
        typeArguments: [
          `${settings.packages.ob_request}::request::Policy<${settings.packages.ob_request}::request::WithNft<${collection.full_type}, ${settings.packages.ob_request}::withdraw_request::WITHDRAW_REQ>>`,
        ],
        arguments: [{ txIndex: 0, index: 0 }],
        types: ["object"],
      },
      {
        type: "transferObjects",
        object: { txIndex: 0, index: 1 },
        to: userAddress,
      },
    ]);
  }

  return promiseTransact(txns);
};

export const burnNFT = async (nft, collection, kiosk) => {
  let txb = false;
  if (kiosk) {
    if (collection.kiosk_enabled) {
      txb = await burnKioskNFT(nft, collection, kiosk);
    } else if (collection.ob_enabled) {
      txb = await burnOBNFT(nft, collection, kiosk);
    }
  } else {
    txb = makeTxnBlock();
    addToTransaction(txb, {
      target: `${collection.object_id}::${collection.module_name}::burn`,
      arguments: [nft.id],
      types: ["object"],
    });
    return signAndExecuteTransactionBlock(txb);
  }
  return signAndExecuteTransactionBlock(txb);
};

// NFT Grab Bag stuff
export const getBags = async (type) => {
  let events = await getEvents({
    MoveEventType: `${settings.object_ids.keepsake_nft_bag}::keepsake_nft_bag::BAG_CREATED<${type}>`,
  });
  if (events.data) {
    return events.data.map((a) => a.parsedJson);
  }
  return false;
};

export const createBag = (nft_collection, rules, txn) => {
  const txb = txn || makeTxnBlock();

  let [kiosk, bag] = addToTransaction(txb, {
    target: `${settings.packages.keepsake_nft_bag}::keepsake_nft_bag::create`,
    typeArguments: [nft_collection.full_type],
  });
  addToTransaction(txb, {
    target: `${SuiAddress}::transfer::public_share_object`,
    typeArguments: [`${SuiAddress}::kiosk::Kiosk`],
    arguments: [kiosk],
    types: ["object"],
  });

  updateBag(nft_collection, rules, { bag_id: bag, tx: txb });
  addToTransaction(txb, {
    target: `${SuiAddress}::transfer::public_share_object`,
    typeArguments: [
      `${settings.packages.keepsake_nft_bag}::keepsake_nft_bag::BagRules<${nft_collection.full_type}>`,
    ],
    arguments: [bag],
    types: ["object"],
  });
  if (txn) {
    return txb;
  }
  return signAndExecuteTransactionBlock(txb);
};

export const updateBags = async (nft_collection, bags) => {
  let txb = makeTxnBlock();
  bags.forEach((bag) => {
    if (bag.hidden) {
      return;
    }
    if (bag.id) {
      let old_bag = nft_collection.grab_bags.find((a) => a.id == bag.id);
      if (JSON.stringify(old_bag) !== JSON.stringify(bag)) {
        updateBag(nft_collection, bag, { bag_id: bag.id, old_values: old_bag, tx: txb });
      }
    } else {
      createBag(nft_collection, bag, txb);
    }
  });
  if (!txb.blockData.transactions.length) {
    return Promise.resolve();
  }
  return signAndExecuteTransactionBlock(txb);
};

export const updateBag = (nft_collection, rules, { bag_id, tx, old_values }) => {
  let txb = tx || makeTxnBlock();

  Object.keys(rules).forEach((key) => {
    let value = rules[key];
    if (old_values?.[key] == value) {
      return;
    }
    switch (key) {
      case "random":
        if (value) {
          addToTransaction(txb, {
            target: `${settings.packages.keepsake_nft_bag}::keepsake_nft_bag::set_rule`,
            typeArguments: [nft_collection.full_type],
            arguments: [bag_id, key, value ? 1 : 0],
            types: ["object", "String", "u64"],
          });
        } else {
          addToTransaction(txb, {
            target: `${settings.packages.keepsake_nft_bag}::keepsake_nft_bag::remove_rule`,
            typeArguments: [nft_collection.full_type],
            arguments: [bag_id, key],
            types: ["object", "String"],
          });
        }
        break;
      case "limit":
        if (value > 0) {
          addToTransaction(txb, {
            target: `${settings.packages.keepsake_nft_bag}::keepsake_nft_bag::set_rule`,
            typeArguments: [nft_collection.full_type],
            arguments: [bag_id, key, value],
            types: ["object", "String", "u64"],
          });
        } else {
          addToTransaction(txb, {
            target: `${settings.packages.keepsake_nft_bag}::keepsake_nft_bag::remove_rule`,
            typeArguments: [nft_collection.full_type],
            arguments: [bag_id, key],
            types: ["object", "String"],
          });
        }
        break;
      case "fee":
        if (value > 0) {
          addToTransaction(txb, {
            target: `${settings.packages.keepsake_nft_bag}::keepsake_nft_bag::set_rule`,
            typeArguments: [nft_collection.full_type],
            arguments: [bag_id, key, suiToMyst(value)],
            types: ["object", "String", "u64"],
          });
        } else {
          addToTransaction(txb, {
            target: `${settings.packages.keepsake_nft_bag}::keepsake_nft_bag::remove_rule`,
            typeArguments: [nft_collection.full_type],
            arguments: [bag_id, key],
            types: ["object", "String"],
          });
        }
        break;
      default:
        break;
    }
  });
  return txb;
};

export const mintToBag = (nft, nft_collection, bag_index, txns) => {
  const txb = txns || makeTxnBlock();

  if (nft_collection.grab_bags?.length == 0) {
    let [kiosk, bag] = addToTransaction(txb, {
      target: `${settings.packages.keepsake_nft_bag}::keepsake_nft_bag::create`,
      typeArguments: [nft_collection.full_type],
    });
    addToTransaction(txb, {
      target: `${settings.packages.keepsake_nft_bag}::keepsake_nft_bag::place`,
      typeArguments: [nft_collection.full_type],
      arguments: [kiosk, bag, nft],
      types: ["object", "object", "object"],
    });
    addToTransaction(txb, {
      target: `${SuiAddress}::transfer::public_share_object`,
      typeArguments: [
        `${settings.packages.keepsake_nft_bag}::keepsake_nft_bag::BagRules<${nft_collection.full_type}>`,
      ],
      arguments: [bag],
      types: ["object"],
    });
    addToTransaction(txb, {
      target: `${SuiAddress}::transfer::public_share_object`,
      typeArguments: [`${SuiAddress}::kiosk::Kiosk`],
      arguments: [kiosk],
      types: ["object"],
    });
  } else {
    let bag = nft_collection.grab_bags[bag_index || 0];
    console.log(nft);
    addToTransaction(txb, {
      target: `${settings.packages.keepsake_nft_bag}::keepsake_nft_bag::place`,
      typeArguments: [nft_collection.full_type],
      arguments: [bag.kiosk_id, bag.id, nft],
      types: ["object", "object", "object"],
    });
  }
  if (txns) {
    return txb;
  }
  return signAndExecuteTransactionBlock(txb);
};

export const addToBag = (nft_collection, bag, nfts) => {
  const txb = makeTxnBlock();
  nfts.forEach((nft) => {
    addToTransaction(txb, {
      target: `${settings.packages.keepsake_nft_bag}::keepsake_nft_bag::place`,
      typeArguments: [nft_collection.full_type],
      arguments: [bag.kiosk_id, bag.id, nft],
      types: ["object", "object", "object"],
    });
  });
  return signAndExecuteTransactionBlock(txb);
};

export const withdrawFromBag = (nft_collection, bag, amount) => {
  const txb = makeTxnBlock();
  for (let i = 0; i < amount; i++) {
    let [nft] = addToTransaction(txb, {
      target: `${settings.packages.keepsake_nft_bag}::keepsake_nft_bag::admin_take`,
      typeArguments: [nft_collection.full_type],
      arguments: [bag.kiosk_id, bag.id, []],
      types: ["object", "object", "Option<ID>"],
    });
    addToTransaction(txb, {
      type: "transferObjects",
      object: nft,
      to: userAddress,
    });
  }
  return signAndExecuteTransactionBlock(txb);
};

export const withdrawFees = async (bags, nft_collection) => {
  const txb = makeTxnBlock();
  // bags.filter(a => a.balance > 0)
};
