import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { findIndexByValue } from "utils/formats";
import { useTitle } from "components/utils/TitleProvider";
import { searchMyFavourites, searchWonAuctions, searchMyBids } from "redux/state/search";
import { getContents } from "redux/state/sui";
import MyFavouritesPanel from "components/layouts/profile/Panels/MyFavouritesPanel";
import MyWonAuctionsPanel from "components/layouts/profile/Panels/MyWonAuctionsPanel";
import MyBidsPanel from "./profile/Panels/MyBidsPanel";
import { useRefreshSearch } from "components/utils/SearchProvider";
import { useSelector } from "react-redux";
import { useProfileMenu } from "components/utils/ProfileMenuProvider";

const MyProfileOther = () => {
  const navigate = useNavigate();
  const { setTitle } = useTitle();
  const { link } = useParams();
  const { setSearchFunction } = useRefreshSearch();
  const { menuIndex, prevMenuIndex, menuData } = useProfileMenu();
  const sui = useSelector((state) => state.sui);
  const parentLink = "profile";

  const dataTabs = [
    {
      title: "Favourites",
      link: "favourites",
      panel: <MyFavouritesPanel title="Favourites" />,
      searchFunction: () => searchMyFavourites,
    },
    {
      title: "My Bids",
      link: "my-bids",
      panel: <MyBidsPanel title="Bids" />,
      searchFunction: () => searchMyBids,
    },
    {
      title: "Won Auctions",
      link: "won-auctions",
      panel: <MyWonAuctionsPanel title="Won Auctions" />,
      searchFunction: () => searchWonAuctions,
    },
  ];

  const [selectedTab, setSelectedTab] = useState(
    Math.max(findIndexByValue(dataTabs, "link", link), 0)
  );

  useEffect(() => {
    const tab = findIndexByValue(dataTabs, "link", link);
    if (tab !== -1 && tab !== selectedTab) {
      setSelectedTab(tab);
      setTitle(dataTabs[tab].title);
      setSearchFunction(dataTabs[tab].searchFunction);
    }
  }, [link]);

  useEffect(() => {
    if (menuIndex !== prevMenuIndex && prevMenuIndex !== null) {
      navigate(`/${parentLink}/${menuData[menuIndex].section}/${dataTabs[0].link}`);
    }
  }, [menuIndex]);

  useEffect(() => {
    setTitle(dataTabs[selectedTab].title);
    setSearchFunction(dataTabs[selectedTab].searchFunction);
  }, []);

  const tabSelectHandler = (index) => {
    const link = dataTabs[index].link;
    navigate(`/${parentLink}/${menuData[menuIndex].section}/${link}`);
  };

  return (
    <section className="tf-section tf-new-profile">
      <div className="flat-tabs items">
        <Tabs selectedIndex={selectedTab} onSelect={(index) => tabSelectHandler(index)}>
          <TabList>
            {dataTabs.map((tab, index) => (
              <Tab key={index} disabled={tab.disabled}>
                {tab.title}
              </Tab>
            ))}
          </TabList>
          {dataTabs.map(({ panel }, index) => (
            <TabPanel key={index}>{panel}</TabPanel>
          ))}
        </Tabs>
      </div>
    </section>
  );
};

export default MyProfileOther;
