import React, { useEffect, useState } from "react";
import { reduxForm } from "redux-form";
import { initFormVals } from "redux/state/initialValues";
import { useParams, useNavigate, Link } from "react-router-dom";
import { connect, useDispatch, useSelector } from "react-redux";
import { getCollection, updateCollection } from "utils/api";
import LoadingSpinner from "components/utils/LoadingSpinner";
import {
  getObjectsOwnedByObject,
  getObjectsInfo,
  updateBags as suiUpdateBags,
  getBags,
  getTransaction,
} from "web3/sui";
import { sleep } from "utils/time";
import { tryAgain } from "utils/performance";
import Header from "components/header/Header";
import PageHeader from "components/layouts/PageHeader";
import Footer from "components/footer/Footer";
import EditBags from "./EditBags";
import LoadingButton from "components/button/LoadingButton";
import ToastPopup from "components/utils/ToastPopup";
import BatchBagCreateModal from "components/layouts/modal/grab_bag/BatchBagCreateModal";
import BatchBagWithdrawModal from "components/layouts/modal/grab_bag/BatchBagWithdrawModal";
import BatchBagTransferModal from "components/layouts/modal/grab_bag/BatchBagTransferModal";
import { SUI_FRAMEWORK_ADDRESS } from "@mysten/sui.js/utils";
import NFTDetailsModal from "components/layouts/modal/NFTDetailsModal";

const formName = "edit-bags";

const validate = (values /*, dispatch */) => {
  const errors = {};
  return errors;
};

const EditBagsForm = (props) => {
  const dispatch = useDispatch();
  const params = useParams();
  const navigate = useNavigate();
  const { handleSubmit, pristine, submitting } = props;
  const form = useSelector((state) => state.form[formName]);
  const user = useSelector((state) => state.user);
  const [collection, setCollection] = useState(false);
  const [batchCreateModalShow, setBatchCreateModalShow] = useState(false);
  const [batchTransferModalShow, setBatchTransferModalShow] = useState(false);
  const [batchWithdrawModalShow, setBatchWithdrawModalShow] = useState(false);
  const [nftDetailsModalShow, setNftDetailsModalShow] = useState(false);
  const { id } = params;
  if (!id) navigate("/profile/listings/collections");

  const fetchCollection = async () => {
    let res = await getCollection(id);
    if (!res.data.collection.object_id) navigate("/profile/listings/collections");
    setCollection(res.data.collection);
    const initVals = res.data.collection;
    let bags = initVals.grab_bags || [];
    const filteredBags = bags.reduce(function (result, a) {
      if (a) result.push(a);
      return result;
    }, []);
    initVals.grab_bags = filteredBags;
    let kioskInfo = await getObjectsInfo(
      filteredBags.map((a) => a?.kiosk_id),
      { showContent: true }
    );
    let nftCounts = kioskInfo.map((a) => a?.data?.item_count);

    try {
      initVals.grab_bags = initVals.grab_bags.map((bag, index) => ({
        ...bag,
        ...{ nftCount: nftCounts[index], uniqueCount: 0 },
        ...{
          collection_name: res.data.collection.name,
          collection_id: res.data.collection._id,
          featured_image: res.data.collection.featured_image,
        },
      }));
    } catch {}
    dispatch(initFormVals(formName, initVals));
  };

  useEffect(() => {
    fetchCollection();
    return () => {
      dispatch(initFormVals(formName));
    };
  }, []);

  const onSubmit = async (values) => {
    let tx = await suiUpdateBags(collection, values.grab_bags);
    if (tx?.effects) {
      await sleep();
      values.tx = tx.effects.transactionDigest;
    }

    let bags = await getBags(collection.full_type);
    bags = bags
      .filter((bag) => {
        let exists = values.grab_bags.find((a) => a.id == bag.id);
        return exists;
      })
      .reverse();

    let bagChildren = await Promise.all(
      bags.map((bag) => getObjectsOwnedByObject(bag.id))
    );

    let childFields = [];
    bagChildren.forEach((children, index) => {
      let interesting = children.data.filter((a) =>
        a.name?.type?.includes("string::String")
      );
      interesting.map((child) => {
        childFields.push(child.objectId);
      });
    });
    let foundChildren = await getObjectsInfo(childFields);

    let bagInfo = [];
    bagChildren.forEach((children, index) => {
      let bag = bags[index];
      bag = values.grab_bags.find((a) => a.id == bag.id);

      let interesting = children.data.filter((a) =>
        a.name?.type?.includes("string::String")
      );
      interesting.map((child) => {
        let field = foundChildren.find((a) => a.id == child.objectId);
        bag[field.data.name] = parseInt(field.data.value);
      });
      bagInfo.push(bag);
    });

    const updatedCollection = await tryAgain(
      () => updateCollection(id, { ...values, ...{ grab_bags: bagInfo } }),
      5
    );
    if (updatedCollection?.data) {
      fetchCollection();
      ToastPopup("Bag successfully updated.", "success");
    }
  };

  const handleCreate = async () => {
    let tx = await suiUpdateBags(collection, [{}]);
    await sleep();

    let txResults = await getTransaction(tx.effects.transactionDigest);
    const created = await getObjectsInfo(
      txResults.effects.created.map((item) => item.reference.objectId)
    );
    let kiosk = created.find((a) =>
      a.type.includes(`${SUI_FRAMEWORK_ADDRESS}::kiosk::Kiosk`)
    );
    let bag = created.find((a) => a.type.includes(`keepsake_nft_bag::BagRules<`));
    let grab_bags = collection.grab_bags || [];
    grab_bags.push({ kiosk_id: kiosk.id, id: bag.id });
    const updatedCollection = await tryAgain(
      () => updateCollection(id, { grab_bags }),
      5
    );
    if (updatedCollection?.data) {
      fetchCollection();
      ToastPopup("Bag successfully updated.", "success");
    }
    return { kiosk_id: kiosk.id, id: bag.id };
  };

  const handleHideModal = () => {
    if (!submitting) {
      setBatchCreateModalShow(false);
      setBatchTransferModalShow(false);
      setBatchWithdrawModalShow(false);
    }
  };

  const onBatchCreate = async () => {
    await fetchCollection();
    handleHideModal();
  };

  if (!collection || !form) {
    return <LoadingSpinner size="xxlarge" absolute />;
  }

  return (
    <div>
      <Header />
      <PageHeader title={`Edit Bags`}></PageHeader>

      <div className="tf-list-item tf-section">
        <div className="themesflat-container">
          <div className="row">
            <div className="col-12">
              <div className="flat-form flat-form-wide">
                <Link to={`/collection-details/${collection._id}`}>
                  <h4 className="mb-4">
                    <span className="fa fa-arrow-left mr-3" />
                    Back to Collection
                  </h4>
                </Link>
                <div className="flat-tabs tab-list-item">
                  <form
                    onSubmit={handleSubmit(onSubmit)}
                    className="flex flex-column align-items-center"
                  >
                    <EditBags
                      handleCreate={handleCreate}
                      title="Edit Bags"
                      collection={collection}
                      setBatchCreateModalShow={setBatchCreateModalShow}
                      setBatchTransferModalShow={setBatchTransferModalShow}
                      setBatchWithdrawModalShow={setBatchWithdrawModalShow}
                      setNftDetailsModalShow={setNftDetailsModalShow}
                    />
                    <LoadingButton
                      type="submit"
                      loading={submitting}
                      disabled={pristine || submitting}
                      style={{ marginTop: "3rem" }}
                    >
                      Save Changes
                    </LoadingButton>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        <BatchBagCreateModal
          onBatchCreate={() => onBatchCreate()}
          item={batchCreateModalShow}
          setModalShow={setBatchCreateModalShow}
          onHide={() => handleHideModal()}
        />
        <BatchBagTransferModal
          onBatchCreate={() => onBatchCreate()}
          item={batchTransferModalShow}
          setModalShow={setBatchTransferModalShow}
          onHide={() => handleHideModal()}
        />
        <BatchBagWithdrawModal
          item={batchWithdrawModalShow}
          onBatchCreate={() => onBatchCreate()}
          onHide={() => handleHideModal()}
        />
        <NFTDetailsModal
          item={nftDetailsModalShow}
          onHide={() => setNftDetailsModalShow(false)}
        />
      </div>
      <Footer />
    </div>
  );
};

export default connect((state) => ({
  initialValues: state.initialValues[formName], // pull initial values from account reducer
}))(
  reduxForm({
    form: formName,
    enableReinitialize: true,
    validate,
  })(EditBagsForm)
);
