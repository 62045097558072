import React from "react";
import { ellipsifyString, ipfsConvert } from "utils/formats.js";
import LazyLoadImage from "components/layouts/LazyLoadImage";
import ToastPopup from "components/utils/ToastPopup";
import EllipsisDropdown from "../EllipsisDropdown";
import styled from "styled-components";

const NftName = styled.span`
  font-size: 18px;
  font-weight: 500;
`;

const NftObjectId = styled.span`
  font-size: 16px;
  font-weight: 700;
  color: var(--primary-color9);
  cursor: pointer;
`;

const OwnedNFTCard = ({
  item,
  sellHandler,
  loanHandler,
  nftDetailsHandler,
  hideNFTHandler = null,
}) => {
  const nftImage = ipfsConvert(item.display?.data?.image_url);

  const handleCopy = () => {
    navigator.clipboard.writeText(item.id);
    ToastPopup("Copied NFT objectId to clipboard!", "success");
  };

  const optionsDropdownList = [
    { label: "Copy Object ID", onClick: handleCopy, icon: <i className="fas fa-copy" /> },
    {
      label: "View Details",
      onClick: () => nftDetailsHandler(item),
      icon: <i className="fas fa-file-invoice" />,
    },
  ];

  if (!item.listed) {
    optionsDropdownList.push({
      label: "Sell NFT",
      onClick: () => sellHandler(item),
      icon: <i className="fas fa-sack-dollar" />,
    });
    optionsDropdownList.push({
      label: "Create Rental",
      onClick: () => loanHandler(item),
      icon: <i className="fas fa-balance-scale-right" />,
    });
  }

  if (hideNFTHandler) {
    optionsDropdownList.push({
      label: "Hide NFT",
      onClick: () => hideNFTHandler(item),
      icon: <i className="fas fa-eye-slash" />,
    });
  }

  return (
    <div className="sc-card-product owned-nft">
      <div className="card-media">
        {nftImage && nftImage?.split(".").pop() === "mp4" ? (
          <video
            className="image-skeleton"
            style={{ zIndex: 1, cursor: "pointer" }}
            onClick={() => nftDetailsHandler(item)}
            autoPlay
            muted
            loop
          >
            <source src={nftImage} type="video/mp4" />
          </video>
        ) : (
          <LazyLoadImage
            src={nftImage}
            className="image-skeleton"
            onClick={() => nftDetailsHandler(item)}
            style={{ cursor: "pointer" }}
          />
        )}
      </div>
      <div className="card-details flex justify-content-between">
        <div className="card-title owned-nft mb-0 align-items-start">
          <NftName>{item.display?.data?.name}</NftName>
          <NftObjectId onClick={handleCopy}>
            {ellipsifyString(item.id, 12, 6)}
          </NftObjectId>
        </div>
        <EllipsisDropdown list={optionsDropdownList} item={item} />
      </div>
    </div>
  );
};

export default OwnedNFTCard;
