import { useNavigate, useSearchParams } from "react-router-dom";
import { Field, reduxForm, change } from "redux-form";
import { connect, useDispatch, useSelector } from "react-redux";
import { mintNFTs } from "web3/sui";
import { renderFormV2 } from "utils/form";
import { getCollection, getLaunchpad } from "utils/api";
import { useState, useEffect } from "react";
import { ipfsConvert, downloadJSONFile } from "utils/formats";
import { useLocation } from "react-router-dom";
import { sleep } from "utils/time";
import ToastPopup from "components/utils/ToastPopup";
import { ExampleNFT } from "assets/fake-data/ExampleNFT";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { isArray } from "lodash";
import { Modal } from "react-bootstrap";
import LoadingSpinner from "components/utils/LoadingSpinner";
import { initFormVals } from "redux/state/initialValues";
import AddFundsTransak from "components/AddFundsTransak";
import LoadingButton from "components/button/LoadingButton";

const formName = "batch-create-nft";

const validate = (/* values , dispatch */) => {
  const errors = {};
  // if (!values.collection) {
  //   errors.collection = "Please select a collection";
  // }
  return errors;
};

const BatchBagCreateModal = ({
  item,
  onHide,
  onBatchCreate,
  handleSubmit,
  pristine,
  submitting,
}) => {
  if (!item) return null;
  const { bag, collection } = item;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [json, setJson] = useState([]);
  const [badFormat, setBadFormat] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [batchSize, setBatchSize] = useState(
    parseInt(searchParams.get("batchSize") || 60)
  );
  const [next, setNext] = useState(parseInt(searchParams.get("start") || 0));
  const tierIndex = collection?.grab_bags?.findIndex((a) => {
    return bag.id == a.id;
  });
  const [batchTierIndex, setBatchTierIndex] = useState(
    searchParams.get("currentTier") || tierIndex
  );
  const [batchType, setBatchType] = useState(searchParams.get("type") || "create");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    dispatch(initFormVals(formName, { ...item, collection }));
  }, []);

  const onJsonLoad = (file) => {
    if (!file) return;
    if (batchTierIndex != tierIndex || batchType !== "create") {
      setSearchParams({});
      setNext(0);
    }
    // do stuff with the file.
    let read = new FileReader();
    read.readAsBinaryString(file);
    read.onloadend = function () {
      let cleanJson = JSON.parse(read.result);
      if (isArray(cleanJson)) {
        setJson(cleanJson);
      } else {
        let keys = Object.keys(cleanJson);
        if (keys.length == 1) {
          setJson(cleanJson[keys[0]]);
        }
      }
    };
  };

  useEffect(() => {
    if (json?.length > 0) {
      if (Array.isArray(json[0].attributes)) {
        setBadFormat(
          'Warning: Attributes is an array, it should be an object of { "attribute_name": ""attribute_value", "color": "blue" } '
        );
      } else if (!json[0].url) {
        ("Warning: No URL detected");
      }
    }
  }, [json]);

  const submit = async () => {
    setLoading(true);
    mintBatch(next, next + batchSize);
  };

  const mintBatch = async (start, end) => {
    if (batchTierIndex != tierIndex || batchType !== "create") setSearchParams({});
    const type = "nft_bag";
    const txn = await mintNFTs(
      {
        collection,
        tier: tierIndex,
      },
      json.slice(start, end),
      type
    );
    if (txn.status === "success") {
      await sleep(2000);
      setNext(parseInt(start) + parseInt(batchSize));
      searchParams.set("start", parseInt(start) + parseInt(batchSize));
      searchParams.set("batchSize", parseInt(batchSize));
      searchParams.set("currentTier", tierIndex);
      searchParams.set("type", "create");
      setSearchParams(searchParams);
    } else {
      ToastPopup("An unknown error occured", "error");
      setLoading(false);
    }
  };

  useEffect(() => {
    if (next > 0 && batchType === "create") {
      if (!json || json.length == 0) {
        if (batchTierIndex == tierIndex) {
          ToastPopup(
            "Please re-upload your NFT JSON file to continue where you left off.",
            "warning"
          );
        }
      } else {
        if (next > json.length) {
          ToastPopup("NFTs minted successfully!", "success");
          setLoading(false);
          setSearchParams({});
          onBatchCreate();
        } else {
          mintBatch(next, next + batchSize);
        }
      }
    }
  }, [next]);

  const handleCopy = () => {
    ToastPopup("Copied info to clipboard!", "success");
  };

  const ReloadBatch = () => {
    navigate(0);
  };

  // if (!collection || !launchpad) {
  //   return <LoadingSpinner size="xxlarge" absolute />;
  // }

  return (
    <Modal size="lg" show={item} onHide={onHide}>
      <Modal.Header closeButton />
      <div className="modal-body space-y-10 pd-40 align-items-center">
        <h2 style={{ marginBottom: "4rem" }}>Add NFTs to {bag?.title || "Bag"}</h2>
        <div className="row">
          <div className="col-lg-7 col-md-12 col-12">
            <h4>Example JSON:</h4>
            <pre className="pre-code-block" style={{ marginBottom: "0" }}>
              {ExampleNFT}
              <div
                style={{
                  cursor: "pointer",
                  position: "absolute",
                  top: "1rem",
                  right: "1rem",
                  display: "flex",
                  gap: "1.5rem",
                  fontSize: "24px",
                }}
              >
                <CopyToClipboard text={ExampleNFT} onCopy={() => handleCopy()}>
                  <i className="fal fa-clone" />
                </CopyToClipboard>
                <i
                  className="far fa-arrow-to-bottom"
                  onClick={() => downloadJSONFile(ExampleNFT, "example-nfts")}
                />
              </div>
            </pre>
          </div>
          <div className="col-xl-5 col-lg-6 col-md-9 col-12">
            <h4 className="title-list-item">Batch NFT Progress</h4>
            <div className="sc-card-product" style={{ marginBottom: "20px" }}>
              <div className="card-media" style={{ height: "260px" }}>
                <img src={ipfsConvert(json[next] ? json[next].url : false)} />
              </div>
              <div className="meta-info p-4">
                <p>Up next: #{Math.min(next, json.length)}</p>
                <p>
                  Minted: {Math.min(next, json.length)}/{json.length}
                </p>
              </div>
            </div>
            <button
              className="fullWidth"
              type="button"
              disabled={pristine || submitting}
              onClick={ReloadBatch}
            >
              Refresh stuck batch
            </button>
          </div>
        </div>
        <form onSubmit={handleSubmit(submit)}>
          <Field
            type="file"
            name="json"
            component={renderFormV2}
            onChange={onJsonLoad}
            className="hideInput"
            containername="h-100"
            labelClassName="h-100"
            hint="JSON file"
            hidename="true"
            accept=".json"
          />
          <AddFundsTransak />
          {badFormat && <p className="error">{badFormat}</p>}
          <div className="fullWidth flex justify-content-center inline-field">
            <LoadingButton
              loading={loading}
              disabled={loading || badFormat || pristine || submitting}
              type="submit"
              className="fullWidth mt-md-4"
            >
              Mint NFTs!
            </LoadingButton>
          </div>
        </form>
      </div>
    </Modal>
  );
};

export default connect((state) => ({
  initialValues: state.initialValues[formName], // pull initial values from account reducer
}))(
  reduxForm({ form: formName, enableReinitialize: true, validate })(BatchBagCreateModal)
);
