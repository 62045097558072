import React, { useContext, useState } from "react";
import ProfileInventory from "pages/profile/ProfileInventory";
import ProfileListings from "pages/profile/ProfileListings";
import ProfileProjects from "pages/profile/ProfileProjects";
import { usePrevious, useWindowSize } from "utils/hooks";
import { findIndexByValue } from "utils/formats";
import { useParams } from "react-router-dom";
import ProfileOther from "pages/profile/ProfileOther";

const ProfileMenuContext = React.createContext();

export const useProfileMenu = () => {
  return useContext(ProfileMenuContext);
};

export const ProfileMenuProvider = ({ children }) => {
  const screenWidth = useWindowSize().width;
  const { section } = useParams();

  const menuData = [
    {
      name: "My Inventory",
      section: "inventory",
      ProfilePanel: <ProfileInventory />,
      Icon: <i className="fas fa-inventory" />,
    },
    {
      name: "My Listings",
      section: "listings",
      ProfilePanel: <ProfileListings />,
      Icon: <i className="fas fa-store" />,
    },
    {
      name: "My Projects",
      section: "projects",
      ProfilePanel: <ProfileProjects />,
      Icon: <i className="fas fa-microscope" />,
    },
    {
      name: "Other",
      section: "other",
      ProfilePanel: <ProfileOther />,
      Icon: <i className="fas fa-inventory" />,
    },
  ];
  const [menuIndex, setMenuIndex] = useState(
    Math.max(findIndexByValue(menuData, "section", section), 0)
  );
  const prevMenuIndex = usePrevious(menuIndex, null);

  const [showMenu, setShowMenu] = useState(screenWidth > 767 ? true : false);

  return (
    <ProfileMenuContext.Provider
      value={{
        menuData,
        showMenu,
        setShowMenu,
        menuIndex,
        setMenuIndex,
        prevMenuIndex,
      }}
    >
      {children}
    </ProfileMenuContext.Provider>
  );
};
