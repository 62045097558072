import React, { useState } from "react";
import { useSelector } from "react-redux";
import SearchBar from "components/layouts/SearchBar";
import PaginationWrapper from "components/layouts/PaginationWrapper";
import Sorting from "components/layouts/Sorting";
import RefreshButton from "components/layouts/RefreshButton";
import { searchLaunchpads, updateLaunchpad } from "utils/api";
import ToastPopup from "components/utils/ToastPopup";
import ReviewLaunchpadModal from "../Modals/ReviewLaunchpadModal";
import LaunchpadCard from "components/layouts/launchpad/LaunchpadCard";
import LaunchpadCardSkeleton from "components/layouts/launchpad/LaunchpadCardSkeleton";
import ListLaunchpadSkeleton from "components/layouts/ListLaunchpadSkeleton";
import ListLaunchpadCard from "components/layouts/ListLaunchpadCard";
import CardList from "components/layouts/explore/Lists/CardList";
import { sortDropdownBasic } from "utils/sortDropdowns";
import { useRefreshSearch } from "components/utils/SearchProvider";
import LayoutButtons from "components/layouts/LayoutButtons";

const ReviewLaunchpadsPanel = ({ title }) => {
  const loading = useSelector((state) => state.search.loading);
  const { refreshSearch, setSortParams } = useRefreshSearch();
  const [count, setCount] = useState(0);
  const [pages, setPages] = useState(1);
  const [layout, setLayout] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const reduxState = useSelector((state) => state.search.launchpads);
  const orderedHeaders = [
    "Name",
    "Floor",
    "Volume",
    "Total Sales",
    "Active Supply",
    "Favorites",
  ];

  const submit = async (values) => {
    const nameTaken = await searchLaunchpads({ name: values.name }).then(
      (res) => res.data.results[0]
    );
    if (nameTaken && nameTaken._id !== values._id) {
      throw new SubmissionError({
        name: "Name taken",
        _error: "A mintpad with that name already exists",
      });
    }

    const updatedGame = await updateLaunchpad(values._id, values);
    if (updatedGame.data) {
      ToastPopup("Mintpad successfully updated.", "success");
    }
  };

  return (
    <div>
      <div className="option" style={{ gap: "2rem" }}>
        <div className="count-and-search fullWidth">
          <h2 className="item-count">
            {loading ? "..." : count} {title}
          </h2>
          <SearchBar />
        </div>
        <div className="view">
          <div className="flex">
            <RefreshButton refreshHandler={refreshSearch} loading={loading} />
            <LayoutButtons layout={layout} setLayout={setLayout} />
          </div>
          <Sorting setSortParams={setSortParams} list={sortDropdownBasic} />
        </div>
      </div>
      <PaginationWrapper pages={pages} count={count}>
        <CardList
          layout={layout}
          title={title}
          setModalShow={setModalShow}
          setCount={setCount}
          setPages={setPages}
          SkeletonComponent={LaunchpadCardSkeleton}
          CardComponent={LaunchpadCard}
          ListSkeletonComponent={ListLaunchpadSkeleton}
          ListCardComponent={ListLaunchpadCard}
          orderedHeaders={orderedHeaders}
          reduxState={reduxState}
          onClick={setModalShow}
        />
      </PaginationWrapper>
      <ReviewLaunchpadModal
        onSubmit={submit}
        onHide={() => setModalShow(null)}
        launchpad={modalShow}
      />
    </div>
  );
};

export default ReviewLaunchpadsPanel;
