import React from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { GridRow, NameImageWrapper, RowImage } from "utils/styled";

const ListBagSkeleton = ({ numberOfSkeletonFields }) => {
  return (
    <GridRow>
      <NameImageWrapper>
        <Skeleton width="150px" height="16px" />
      </NameImageWrapper>
      {Array.from({ length: numberOfSkeletonFields }, (_, index) => (
        <Skeleton key={index} width="50%" height="16px" />
      ))}
    </GridRow>
  );
};

export default ListBagSkeleton;
